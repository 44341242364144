import { uniqBy } from "lodash";
import { filterRecommendations, productsConstants } from "../_constants";

const initState = {
  loading: false,
  page: 0,
  pages: 0,
  recommendations: [],
  quizItems: [],
  quickviewItem: {},
  similarProducts: [],
};

export function products(state = initState, action) {
  switch (action.type) {
    case productsConstants.QUIZ_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsConstants.QUIZ_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        quizItems: action.data.with,
      };
    case productsConstants.RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        page: action.page,
        recommendations: action.page ? state.recommendations : [],
        loading: true,
      };
    case productsConstants.RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendations: action.recommendations.with,
      };
    case productsConstants.RECOMMENDATIONS_PAGE_SUCCESS:
      const { recommendations = [] } = state;
      const { page } = action.data;
      const { results, pages } = action.data.with;

      const recs = results;
      // const { recs = [] } = filterRecommendations(results);

      return {
        ...state,
        loading: false,
        page,
        pages,
        recommendations: uniqBy([...recommendations, ...recs], "itemId"),
      };
    case productsConstants.RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productsConstants.RECOMMENDATIONS_CLEAR:
      return {
        ...state,
        page: 0,
        recommendations: [],
      };
    case productsConstants.SHOPPER_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productsConstants.SET_QUICKVIEW:
      return {
        ...state,
        quickviewItem: action.item,
      };
    case productsConstants.GET_SIMILAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsConstants.GET_SIMILAR_SUCCESS:
      return {
        ...state,
        loading: false,
        similarProducts: action.data.with,
      };
    case productsConstants.GET_SIMILAR_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case productsConstants.GET_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productsConstants.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        quickviewItem: action.product.with,
      };
    case productsConstants.GET_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
