import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Route,
  useRouteMatch,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import SearchResults from "../../components/SearchResults";

import get from "lodash/get";
import Container from "react-bootstrap/Container";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
// import { DesktopCategories } from "../../components/Navs/DesktopCategories";
import Footer from "../../components/Navs/Footer/index.js";
import AddTaggBanner from "../../components/AddTaggBanner";
import MyShop from "./my-shop";
import { CategoryPage } from "./CategoryPage";
import { SubcategoryPage } from "./SubcategoryPage";
import { productsActions } from "../../_actions";
import "./styles.css";
import {TaggedPage} from "./TaggedPage";

const Home = ({ dispatch, loggedIn, recommendations, likes, categories, loading }) => {
  let match = useRouteMatch();
  const [currentSection, setCurrentSection] = useState(0);
  const [showSearch, setShowSearch] = useState(false);

  return loggedIn ? (
    <>
      <SiteNav
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        withCategories
        showSearch={showSearch}
        setShowSearch={setShowSearch}
      />
      <div className='body-padding' />
      {!showSearch && (
        <>
          <Route path={`${match.path}/tagged`}>
            <TaggedPage
                shopItems={likes}
                setCurrentSection={setCurrentSection}
            />
          </Route>
          <Route path={`${match.path}/category/:categoryId`}>
            <CategoryPage
              shopItems={recommendations}
              setCurrentSection={setCurrentSection}
            />
          </Route>
          <Route path={`${match.path}/sub/:categoryId/:subcategoryId`}>
            <SubcategoryPage
              shopItems={recommendations}
              setCurrentSection={setCurrentSection}
            />
          </Route>
          <Route exact path={match.path}>
            <MyShop shopItems={recommendations} dispatch={dispatch} />
          </Route>
        </>
      )}

      <AddTaggBanner />
      <Footer />
    </>
  ) : (
    <Redirect to='/' />
  );
};

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { products, user, siteConfig } = state;
  const likes = get(user, "likes", []);
  const recommendations = get(products, "recommendations", []);
  const categories = get(siteConfig, "categories", []);
  const loading = get(user, "loading", false);

  return {
    loggedIn,
    recommendations,
    likes,
    categories,
    loading,
  };
}

const connectedHome = withRouter(connect(mapStateToProps)(Home));
export { connectedHome as Home };
