import { userConstants } from "../_constants";
import { productsService, userService } from "../_services";
import { alertActions, siteConfigActions } from "./";
import { history } from "../_helpers";
import { persistor } from "../_helpers/store";

export const userActions = {
  clearErrors,
  login,
  getUser,
  getShopper,
  signup,
  verify,
  addProfilePic,
  removeProfilePic,
  updateUser,
  forgotPassword,
  resetPassword,
  changePassword,
  resendCode,
  updateFirstName,
  updateLastName,
  updateEmail,
  updateBirthday,
  updateSlug,
  setFirstName,
  setLastName,
  setEmail,
  setBirthday,
  setUsername,
  setReduxPw,
  logout,
};

function getShopper(username) {
  return (dispatch) => {
    dispatch(request());
    userService.getShopper(username).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_SHOPPER_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_SHOPPER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_SHOPPER_FAILURE, error };
  }
}

function getUser() {
  return (dispatch) => {
    dispatch(request());
    userService.getUser().then(
      async (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      (response) => {
        dispatch(success(response));
        dispatch(siteConfigActions.getSiteConfig());
        dispatch(siteConfigActions.getCategories());
        dispatch(getUser());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function signup(signupObject) {
  return (dispatch) => {
    dispatch(request());

    userService.signup(signupObject).then(
      (response) => {
        dispatch(success(response));
        // dispatch(getUser());
        // dispatch(siteConfigActions.getSiteConfig());
        // dispatch(siteConfigActions.getCategories());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(signupObject) {
    return { type: userConstants.SIGNUP_REQUEST, signupObject };
  }
  function success(signupObject) {
    return { type: userConstants.SIGNUP_SUCCESS, signupObject };
  }
  function failure(error) {
    return { type: userConstants.SIGNUP_FAILURE, error };
  }
}

function verify(verificationObject) {
  return (dispatch) => {
    dispatch(request());

    userService.verify(verificationObject).then(
      (response) => {
        dispatch(success(response));
        dispatch(getUser());
        dispatch(siteConfigActions.getSiteConfig());
        dispatch(siteConfigActions.getCategories());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(verificationObject) {
    return { type: userConstants.VERIFY_SIGNUP_REQUEST, verificationObject };
  }
  function success(verificationObject) {
    return { type: userConstants.VERIFY_SIGNUP_SUCCESS, verificationObject };
  }
  function failure(error) {
    return { type: userConstants.VERIFY_SIGNUP_FAILURE, error };
  }
}

function updateUser(userObject) {
  return (dispatch) => {
    dispatch(request());

    userService.updateUser(userObject).then(
      (response) => {
        dispatch(success(response));
        dispatch(getUser());
      },
      (error) => dispatch(failure(error))
    );
  };

  function request(userObject) {
    return { type: userConstants.UPDATE_USER_REQUEST, userObject };
  }
  function success(userObject) {
    return { type: userConstants.UPDATE_USER_SUCCESS, userObject };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}

function addProfilePic(blob) {
  return (dispatch) => {
    dispatch(request());

    userService.addProfilePic(blob).then(
      (response) => {
        dispatch(success(response));
        dispatch(
          updateUser({
            avatar: response.with.originalUrl,
            avatarThumb: response.with.thumbnailUrl,
          })
        );
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.ADD_PROFILE_PIC_REQUEST };
  }
  function success(response) {
    return { type: userConstants.ADD_PROFILE_PIC_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.ADD_PROFILE_PIC_FAILURE, error };
  }
}

function removeProfilePic() {
  return { type: userConstants.REMOVE_PROFILE_PIC };
}

function forgotPassword(usernameObj) {
  return (dispatch) => {
    dispatch(request());
    userService.forgotPassword(usernameObj).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(response) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(passwordObj) {
  const username = passwordObj.username;
  return (dispatch) => {
    dispatch(request({ username }));
    userService.resetPassword(passwordObj).then(
      (response) => {
        dispatch(success(response));
        dispatch(getUser());
        dispatch(siteConfigActions.getSiteConfig());
        dispatch(siteConfigActions.getCategories());
      },
      (error) => {
        if ((error = "Internal Server Error")) {
          dispatch(
            alertActions.error(
              "Error verifying new password. Please ensure all fields are entered correctly, with no spaces."
            )
          );
        }
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function clearErrors() {
  return { type: userConstants.CLEAR_ERRORS };
}

function changePassword(passwordObj) {
  return (dispatch) => {
    dispatch(request());
    userService.changePassword(passwordObj).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(response) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function resendCode(email) {
  return (dispatch) => {
    dispatch(request());

    userService.resendCode(email).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.RESEND_CODE_REQUEST };
  }
  function success(response) {
    return { type: userConstants.RESEND_CODE_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.RESEND_CODE_FAILURE, error };
  }
}

function updateFirstName(firstName) {
  return { type: userConstants.UPDATE_FIRSTNAME, firstName };
}

function updateLastName(lastName) {
  return { type: userConstants.UPDATE_LASTNAME, lastName };
}

function updateEmail(email) {
  return { type: userConstants.UPDATE_EMAIL, email };
}

function updateBirthday(birthday) {
  return { type: userConstants.UPDATE_BIRTHDAY, birthday };
}

function updateSlug(slug) {
  return { type: userConstants.UPDATE_SLUG, slug };
}

function setFirstName(firstName) {
  return { type: userConstants.SET_FIRSTNAME, firstName };
}

function setLastName(lastName) {
  return { type: userConstants.SET_LASTNAME, lastName };
}

function setEmail(email) {
  return { type: userConstants.SET_EMAIL, email };
}

function setBirthday(birthday) {
  return { type: userConstants.SET_BIRTHDAY, birthday };
}

function setUsername(username) {
  return { type: userConstants.SET_USERNAME, username };
}

function setReduxPw(pw) {
  return { type: userConstants.SET_REDUX_PW, pw };
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}
