import { siteConfigConstants } from "../_constants";

const categories = [
  {
    id: 0,
    categoryName: "My Shop",
  },
  {
    id: 6,
    categoryName: "Tagged",
  },
  {
    id: 1,
    categoryName: "Tops",
    categoryFilter: "CATEGORY_TOPS",
  },
  {
    id: 2,
    categoryName: "Bottoms",
    categoryFilter: "CATEGORY_BOTTOMS",
  },
  {
    id: 3,
    categoryName: "Dresses",
    categoryFilter: "CATEGORY_DRESSES",
  },
  {
    id: 4,
    categoryName: "Outerwear",
    categoryFilter: "CATEGORY_OUTERWEAR",
  },
  {
    id: 5,
    categoryName: "Swimwear",
    categoryFilter: "CATEGORY_SWIMSUITS",
  },
  {
    id: 7,
    categoryName: "Accessories",
    categoryFilter: "CATEGORY_ACCESSORIES",
  },
];

const initState = {
  loading: false,
  data: {},
  affiliates: [],
  categories,
};

export function siteConfig(state = initState, action) {
  switch (action.type) {
    case siteConfigConstants.GET_SITE_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConfigConstants.GET_SITE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.with,
        affiliates: action.response.with.affiliates,
      };
    case siteConfigConstants.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConfigConstants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories,
      };
    default:
      return state;
  }
}
