import React, { useState } from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import AddTaggBanner from "../../components/AddTaggBanner";

import "./styles.css";

const About = () => {
  return (
    <>
      <SiteNav />
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={7} sm={12} xs={12} className="shadow-box p-4">
            <h1 style={{ textAlign: "left", marginTop: "15px" }}>About Tagg</h1>
            <hr className="line-left" />
            <p align="left" style={{ padding: "20px", fontSize: "15px" }}>
              Hundreds of retailers and thousands of products. Shopping online
              has never been more challenging. Tagg Me curates the top online
              retailers and selects the perfect products for your unique style.
              <br />
              <br />
              Tagg Me selects and partners with online retailers with proven
              quality and that carry top brands of clothing and accessories.
              Tagg Me then combines products from a collection of stores allowing
              you to browse, search, and shop in one simple experience. Tagg Me is
              unique to you. Out of millions of products, Tagg Me curates a
              boutique of special products selected just for you.
              <br />
              <br />
              Tagg Me shops with you and uses machine learning to stock your
              personal shop with products you'll love. Tagg Me collects what you
              like based on your viewing patterns and purchases when using the
              Google Chrome Extension to learn your unique style. Your Tagg Me Shop
              surfaces items specific to you and allows you to further fine tune
              what you want to see by liking, disliking, and purchasing items
              from your Tagg Me Shop. <br />
              <br /> Users across the world, shopping at the top online
              retailers, continually uncover the freshest products across all
              price points, styles, and brands to train the Tagg Me shopping
              engine. <br />
              <br />I created Tagg Me to make the online shopping experience easier
              and more personal. I hope you enjoy using it as much as I do!{" "}
              <br />
              <br />
              <br />
              <br />
              <p style={{ marginLeft: "75px", fontSize: "14px" }}>
                Enjoy, <br />
                <br />
                Lauren Willian
                <br /> Founder of Tagg
              </p>
            </p>
          </Col>
        </Row>
        <AddTaggBanner />
        <Footer />
      </Container>
    </>
  );
};

export default About;
