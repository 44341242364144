import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Redirect, useHistory } from "react-router-dom";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import AddTaggBanner from "../../components/AddTaggBanner";
import copyUrl from "../../assets/images/copy-url.png";

import ProductGrid from "../../components/ProductGrid";
import {
  productsActions,
  alertActions,
  userActions,
  uiActions,
} from "../../_actions";
import { appConstants } from "../../_constants/app.constants";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";

import "./styles.css";

const Shopper = ({
  dispatch,
  recommendations,
  shopperDetails,
  showCta,
  firstName,
  avatar,
  error,
}) => {
  let { slug } = useParams();
  const loadPage = (page) => {
    dispatch(productsActions.getShopperRecommendations(slug, page));
  };
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    dispatch(userActions.getShopper(slug));
    dispatch(alertActions.clear());
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  useEffect(() => {
    loadPage(1);
  }, []);
  
  return (
    <>
      <Container fluid className='p-0'>
        <SiteNav />
        <Col
          sm={12}
          className='align-self-center'
        >
          {error === "Could not find user" ? (
            <>
              <Row className='justify-content-center'>
                <h3 className='mb-0'>
                  Sorry! We can't find a user with that name. Please try again.
                </h3>
              </Row>
            </>
          ) : (
            <Row className='justify-content-center'>
              <Col md={7} sm={12} xs={12}>
                <Row className='m-0 justify-content-start'>
                  <Col
                    md={"auto"}
                    sm={"auto"}
                    xs={2}
                    className='p-0'
                    align='left'
                  >
                    <img
                      src={avatar}
                      id='shopper-closet-avatar'
                      alt='shopper-closet-avatar'
                    />
                  </Col>
                  <Col
                    md={"auto"}
                    sm={"auto"}
                    xs={9}
                    className=' my-auto pl-1'
                    align='left'
                  >
                    <h3 align='left' className='mb-0'>
                      Check out {firstName}'s style
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col md={5} sm={12} xs={12} align='right' className='my-auto'>
                <span
                  align='right'
                  // className='small'
                  className='shopper-url-prefix-slug'
                  onClick={() => dispatch(uiActions.showCallToAction(true))}
                >
                  shop.tagg.me/shopper/{slug}
                </span>
                <img
                  src={copyUrl}
                  alt='copy-url'
                  ref={target}
                  className='m-1'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://shop.tagg.me/shopper/${slug}`
                    );

                    setShowTooltip(!showTooltip);
                  }}
                />
                <Overlay
                  target={target.current}
                  show={showTooltip}
                  placement='bottom'
                  onHide={() => setShowTooltip(false)}
                  rootClose
                >
                  {(props) => (
                    <Tooltip
                      id='overlay-example'
                      className='tooltip'
                      {...props}
                      autohide
                    >
                      Copied!
                    </Tooltip>
                  )}
                </Overlay>
              </Col>
            </Row>
          )}
            </Col>
          <Row className='mt-0'>

            <ProductGrid shopItems={recommendations} loadMore={loadPage}/>
            <hr />

            <Modal
              show={showCta}
              onHide={() => dispatch(uiActions.showCallToAction(false))}
              top
              dialogClassName='modal-90w'
            >
              <Modal.Body closeButton className='p-0'>
                <Col md={{ span: 4, offset: 8 }} align='right'>
                  <Button
                    className='btn-icon'
                    align='right'
                    onClick={() => dispatch(uiActions.showCallToAction(false))}
                  >
                    <XClose />
                  </Button>
                </Col>
                <Row className='justify-content-center mt-0 p-4'>
                  <Col md={12}>
                    <h1>Ready to find your style?</h1>
                  </Col>
                  <Col md={12} className='mb-4'>
                    <p align='center'>
                      With Tagg, you can quickly uncover the freshest products
                      across all price points, styles, and brands that perfectly
                      match your unique style.
                    </p>
                  </Col>

                  <Col xs={"auto"}>
                    {/* <Link to="/" onClick={() => setShowCta(true)}> */}
                    <Button
                      className='btn-decline btn-quiz mb-1'
                      onClick={() =>
                        dispatch(uiActions.showCallToAction(false))
                      }
                    >
                      Not right now
                    </Button>
                    {/* </Link> */}
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      onClick={() => {
                        dispatch(uiActions.showCallToAction(false));
                        history.push("/");
                      }}
                      className='btn-orange btn-quiz mb-1'
                    >
                      Get Started
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Row>
        <AddTaggBanner />
        <Footer />
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  const { products, alert, user, ui } = state;
  const recommendations = get(products, "recommendations", []);
  const shopperDetails = get(user, "shopperDetails", { name: "  " });
  const firstName = shopperDetails.name.split(" ")[0] || null;

  const avatar = get(shopperDetails, "avatar", appConstants.placeholderAvatar);
  const error = get(user, "error", "");
  const showCta = get(ui, "showCallToAction", false);

  return {
    recommendations,
    shopperDetails,
    firstName,
    avatar,
    showCta,
    error,
  };
}

const connectedShopper = connect(mapStateToProps)(Shopper);
export { connectedShopper as Shopper };
