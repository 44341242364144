import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../_actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Logo from "../../../assets/images/new-logo.svg";
import Jane from "../../../assets/images/jane.png";
import { ReactComponent as Menu } from "../../../assets/svg/menu.svg";
import { ReactComponent as XClose } from "../../../assets/svg/xclose.svg";
import Avatar from "../../../assets/svg/avatar.svg";
import { appConstants } from "../../../_constants";
import { DesktopCategories } from "../DesktopCategories/index.js";
import { MobileCategories } from "../MobileCategories/index.js";
import SearchResults from "../../SearchResults";

import "./styles.css";

const SiteNav = ({
  currentSection,
  setCurrentSection,
  greetingName,
  loggedIn,
  avatar,
  withCategories,
  showSearch,
  setShowSearch,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchTerm, setShowSearchTerm] = useState("");

  return (
    <>
      <Navbar
        expand="md"
        className="shadow-bottom"
        fixed={loggedIn && withCategories ? "top" : null}
      >
        <Container fluid>
          {/* mobile hamburger menu */}
          {loggedIn && withCategories && (
            <Nav.Item as="span" className="visibledevice nav-left" align="left">
              {showMenu && <XClose onClick={() => setShowMenu(!showMenu)} />}
              {!showMenu && <Menu onClick={() => setShowMenu(!showMenu)} />}
            </Nav.Item>
          )}
          {/* end mobile hamburger menu */}
          <Link to="/" className="logoOuter">
            <img src={Logo} alt="logo" id="logo" />
          </Link>

          {loggedIn ? (
            <Link to="/Profile" className="nav-right site-nav">
              <Row className="m-0 p-0 align-items-center">
                <span className="visibledesktop">{greetingName}</span>
                {avatar === null ? (
                  <img src={Avatar} id="nav-avatar" alt="nav-avatar" />
                ) : (
                  <img src={avatar} id="nav-avatar" alt="nav-avatar" />
                )}
              </Row>
            </Link>
          ) : (
            <>
              <Nav.Item className="login-link" as="span">
                <Link to="/Login">Log in</Link>
              </Nav.Item>
            </>
          )}
        </Container>
      </Navbar>
      {loggedIn && withCategories && (
        <>
          <DesktopCategories
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            className="visibledesktop"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showSearchTerm={showSearchTerm}
            setShowSearchTerm={setShowSearchTerm}
          />
          <MobileCategories
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            className="visibledevice"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showSearchTerm={showSearchTerm}
            setShowSearchTerm={setShowSearchTerm}
          />
        </>
      )}
      {showSearch && (
        <>
          <SearchResults
            searchTerm={searchTerm}
            setCurrentSection={setCurrentSection}
            setShowSearch={setShowSearch}
          />
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { authentication, user } = state;
  const userDetails = get(user, "userDetails", {});
  const loggedIn = get(authentication, "loggedIn", false);
  const greetingName = get(user, "greetingName", "");
  const avatar = get(
    user,
    "userDetails.avatar",
    appConstants.placeholderAvatar
  );

  return {
    loggedIn,
    greetingName,
    avatar,
  };
}

const connectedSiteNav = connect(mapStateToProps)(SiteNav);
export { connectedSiteNav as SiteNav };
