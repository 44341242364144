import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";
import { userActions } from "../_actions";
import { userService } from "./user.service";
import fetch from "./refreshableFetch";

import get from "lodash/get";

const ITEMS_PER_PAGE = 500;

export const productsService = {
  getRecommendations,
  getPaginatedRecommendations,
  getShopperRecommendations,
  getProduct,
  getSimilar,
  postLike,
  postDislike,
  deleteLike,
  deleteDislike,
  getQuizItems,
};

function getQuizItems(category) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.apiUrl}quiz/items/${category}`,
    requestOptions
  ).then(handleResponse);
}

function getRecommendations(category) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${appConstants.apiUrl}recommendations`;
  if (category) {
    url += `?category=${category}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getPaginatedRecommendations(page = 1, category) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${appConstants.apiUrl}recommendations/paginated?page=${page}&count=${ITEMS_PER_PAGE}`;
  if (category) {
    url += `&category=${category}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getShopperRecommendations(slug, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${appConstants.apiUrl}users/${slug}/recommendations/paginated?page=${page}&count=${ITEMS_PER_PAGE}`,
    requestOptions
  ).then(handleResponse);
}

function getProduct(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(`${appConstants.apiUrl}products/${id}`, requestOptions).then(
    handleResponse
  );
}

function getSimilar(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.apiUrl}recommendations/item?canonical=${url}&count=6&page=1`,
    requestOptions
  ).then(handleResponse);
}

async function postLike(itemId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ itemId, userId }),
  };

  return fetch(`${appConstants.apiUrl}like`, requestOptions).then(
    handleResponse
  );
}

function postDislike(itemId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ itemId, userId }),
  };

  return fetch(`${appConstants.apiUrl}dislike`, requestOptions).then(
    handleResponse
  );
}

async function deleteLike(itemId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ itemId, userId }),
  };

  return fetch(`${appConstants.apiUrl}like`, requestOptions).then(
    handleResponse
  );
}

function deleteDislike(itemId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ itemId, userId }),
  };

  return fetch(`${appConstants.apiUrl}dislike`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        userActions.logout();
      }
      if (response.status === 400) {
        const error = (data && data.because) || response.statusText;
        return Promise.reject(error);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
