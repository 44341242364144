import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import ProductGrid from "../../components/ProductGrid";
import { ShopHeader } from "../../components/ShopHeader";

import { productsActions } from "../../_actions";
import "./styles.css";

const Home = ({ dispatch, shopItems, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(productsActions.clearRecommendations());
    dispatch(productsActions.getPaginatedRecommendations(1));
  }, []);

  const loadPage = (page) => {
    setSlice(slice + 16);
  };

  const [slice, setSlice] = useState(16);

  return (
    <Row className='ml-1 mr-1 justify-content-center'>
        <Col
          sm={12}
          xl={12}
          className='align-self-center'
        >
          <ShopHeader />
        </Col>
        <ProductGrid
          shopItems={shopItems}
          loadMore={loadPage}
          location={location}
          slice={slice}
      />
      </Row>
  );
};

export default Home;
