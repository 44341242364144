import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ProductGrid from "../../components/ProductGrid";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  connectHits,
  Configure,
} from "react-instantsearch-dom";

const SearchResults = ({ searchTerm }) => {
  const [searchState, setSearchState] = useState({ query: searchTerm });

  const searchClient = algoliasearch(
    "JYIXJM80IW",
    "b9be1fdbe4d43410cef4a3f5efe12559"
  );

  let history = useHistory();
  let match = useRouteMatch();
  let back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const Hits = ({ hits }) => <ProductGrid search shopItems={hits} />;

  const CustomHits = connectHits(Hits);

  // Update searchState whenever searchTerm changes
  useEffect(() => {
    setSearchState({ query: searchTerm });
  }, [searchTerm]);

  return (
    <>
      <div className='body-padding' />
      <InstantSearch
        indexName="prod_TAGG_new"
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <Configure hitsPerPage={48} />
        <Row className="justify-content-center m-4">
          <Col sm={12} xl={6} className="align-self-center">
            <h4 className="category-header">Search Results {searchState.query && ( " for: " + searchState.query )}</h4>
          </Col>
          <Row className="ml-1 mr-1 justify-content-center">
            <SearchBox />
            <CustomHits />
          </Row>
        </Row>
      </InstantSearch>
    </>
  );
};

export default SearchResults;