import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { productsActions } from "../../_actions/products.actions";
import ProductGrid from "../../components/ProductGrid";

import get from "lodash/get";
import "./styles.css";
import { uiActions, userActions } from "../../_actions";

const TaggedPage = ({ dispatch, recommendations, categories }) => {
  const loadPage = (page) => {
    setSlice(slice + 16);
  };

  const [slice, setSlice] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSlice(0);
    dispatch(userActions.getUser());
  }, []);

  const productGrid = (
    <ProductGrid
      shopItems={recommendations}
      loadMore={loadPage}
      slice={slice}
    />
  );

  const emptyText = (
    <div className="constrict">
      <p className="ml-2 pr-4">
        You have no Tagged items. You can tag items by tapping on the tag icon
        in the upper right corner of the image. Tagged items help to define your
        style and show others what you really love.
      </p>
    </div>
  );

  return (
    <Row className="ml-1 mr-1 justify-content-center">
        <Col
          sm={12}
          xl={6}
          className="align-self-center"
        >
          <h4 className="category-header">Tagged</h4>
        </Col>
        {recommendations.length ? productGrid : emptyText}
      </Row>
  );
};

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { products, user, siteConfig } = state;
  const recommendations = get(user, "likes", []);
  const categories = get(siteConfig, "categories", []);
  const loading = get(user, "loading", false);

  const recPage = products.page;

  return {
    loggedIn,
    recommendations,
    categories,
    loading,
    recPage,
  };
}

const connectedTaggedPage = connect(mapStateToProps)(TaggedPage);
export { connectedTaggedPage as TaggedPage };
