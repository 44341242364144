import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrangeButton from "../../components/Buttons/OrangeButton";
import "./styles.css";

const ContactForm = ({ dispatch, firstName, lastName, email, loading }) => {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [contactEmail, setContactEmail] = useState(email);
  const [contactName, setContactName] = useState(firstName + lastName);
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    const form = document.forms[0];

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setSubmitted(true);
    }
  };

  const mailToString = `mailto:support@tagg.me?subject=Tagg Me Support request from ${contactName}&body=${message}`;

  return (
    <>
      <Row className="justify-content-center">
        <Col md={12}>
          <h1>Contact Us</h1>
        </Col>
        <Col md={12} className="mt-2">
          <Form
            noValidate
            validated={validated}
            onChange={() => setSubmitted(false)}
          >
            <Form.Group align="left">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                as="input"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group align="left">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                as="input"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group align="left">
              <Form.Label>Message</Form.Label>
              <Form.Control
                required
                as="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please include a message.
              </Form.Control.Feedback>
            </Form.Group>

            <a href={mailToString}>
              <OrangeButton
                buttonText={loading ? "Submitting..." : "Send Message"}
              />
            </a>

            {submitted && (
              <Col md={12}>
                <p>Thanks, we will get back to you!</p>
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  const { newUser } = state;
  const firstName = get(newUser, "data.firstName", "");
  const lastName = get(newUser, "data.lastName", "");
  const email = get(newUser, "data.email", "");
  const loading = get(newUser, "loading", false);

  return {
    firstName,
    lastName,
    email,
    loading,
  };
}

const connectedContactForm = connect(mapStateToProps)(ContactForm);
export { connectedContactForm as ContactForm };
