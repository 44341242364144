import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { ReactComponent as Ig } from "../../../assets/svg/instagram.svg";
import { ReactComponent as Fb } from "../../../assets/svg/facebook.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/twitter.svg";
import { linkConstants } from "../../../_constants";
// import { ReactComponent as Email } from "../../../assets/svg/email.svg";

import "./styles.css";

const Footer = ({ isScrolling }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Navbar
      // sticky="bottom"
      className={`footer${isScrolling ? " scrolling" : ""}`}
    >
      <Container>
        <Row className="footer-row">
          <Col md={7} xs={12} className="footer-col" align="top">
            <Row className="justify-content-center">
              <Col md={4} xs={6} align="left" className="">
                <h5>Info</h5>
                <Link to={{ pathname: linkConstants.ABOUT }} target="_blank">
                  <p className="footer-link">About Tagg</p>
                </Link>
                <Link to={{ pathname: linkConstants.FAQ }} target="_blank">
                  <p className="footer-link">FAQ</p>
                </Link>
                <Link to={{ pathname: linkConstants.PARTNERS }} target="_blank">
                  <p className="footer-link">Partners</p>
                </Link>
                <Link to={{ pathname: linkConstants.CONTACT }} target="_blank">
                  <p className="footer-link">Contact Us</p>
                </Link>
              </Col>
              <Col md={4} xs={6} align="left" className="">
                <h5>Policies</h5>
                <Link to={{ pathname: linkConstants.PRIVACY }} target="_blank">
                  <p className="footer-link">Privacy</p>
                </Link>
                <Link to={{ pathname: linkConstants.TERMS }} target="_blank">
                  <p className="footer-link">Terms and Conditions</p>
                </Link>
                <br />
                <br />
                <br />
                <br />
              </Col>
            </Row>
          </Col>
          <Col md={5} xs={12} className="h-100 mt-0">
            <Row>
              <Col md={12} align="left">
                <h5>Subscribe for our newsletter</h5>
                <Form.Control as="input" placeholder="Email Address" />
                <br />
                <Button className="social-icons">
                  <Twitter />
                </Button>
                <Button className="social-icons">
                  <Fb />
                </Button>
                <Button className="social-icons">
                  <Ig />
                </Button>
                <br />
                <br />
                <br />
                <br />
              </Col>
            </Row>
          </Col>
        </Row>{" "}
        <p align="right" className="trademark mt-4">
          Tagg, 2019. All rights reserved.
        </p>
      </Container>
    </Navbar>
  );
};
const mapStateToProps = (state) => ({
  isScrolling: get(state, "ui.scrolling", false),
});

export default connect(mapStateToProps)(Footer);
