import React, { useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ProductGrid from "../../components/ProductGrid";
import { productsActions } from "../../_actions";
import { useParams, withRouter } from "react-router-dom";

import get from "lodash/get";
import "./styles.css";

const SubcategoryPage = ({
  recommendations,
  loadMore,
  categories,
  dispatch
}) => {
  let { subcategoryId, categoryId } = useParams();

  const parentCategory = get(categories, categoryId, "");
  const category = categories[parentCategory.id];
  const subcategoryName = category.subcategories[subcategoryId];

  // useEffect(() => {
  //   dispatch(productsActions.getRecommendations());
  // }, [categoryId]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12} align="left">
            <h4 className="category-header">
              {parentCategory.categoryName} / {subcategoryName}
            </h4>
          </Col>
          <ProductGrid shopItems={recommendations} loadMore={loadMore} />
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { products, user, siteConfig } = state;
  const recommendations = get(products, "recommendations", []);
  const categories = get(siteConfig, "categories", []);
  const loading = get(user, "loading", false);

  return {
    loggedIn,
    recommendations,
    categories,
    loading
  };
}

const connectedSubcategoryPage = withRouter(
  connect(mapStateToProps)(SubcategoryPage)
);
export { connectedSubcategoryPage as SubcategoryPage };
