import { userConstants } from "../_constants";
import split from "lodash/split";
import get from "lodash/";

export function user(
  state = {
    loading: false,
    error: null,
    updated: null,
    userDetails: {},
    firstName: null,
    lastName: null,
    username: null,
    greetingName: null,
    likes: []
  },
  action
) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        signupError: null,
      };
    case userConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        updated: null,
      };
    case userConstants.GET_USER_SUCCESS:
      const name = action.data.with.name;
      const username = action.data.with.slug;
      const firstName = name ? name.split(" ")[0] : "";
      const lastName = name ? name.split(" ")[1] : "";

      return {
        ...state,
        loading: false,
        userDetails: action.data.with,
        firstName: firstName,
        lastName: lastName,
        greetingName: firstName,
        username: username,
        likes: action.data.with.likes,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userConstants.GET_SHOPPER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case userConstants.GET_SHOPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        shopperDetails: action.data.with,
      };
    case userConstants.GET_SHOPPER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not find user",
      };
    case userConstants.ADD_PROFILE_PIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.ADD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.ADD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userConstants.REMOVE_PROFILE_PIC:
      return {
        ...state,
        loading: false,
      };

    case userConstants.UPDATE_FIRSTNAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case userConstants.UPDATE_LASTNAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case userConstants.UPDATE_EMAIL:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          email: action.email,
        },
      };
    case userConstants.UPDATE_BIRTHDAY:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          birthdate: action.birthday,
        },
      };
    case userConstants.UPDATE_SLUG:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          slug: action.slug,
        },
      };
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        updated: true,
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
