import { siteConfigConstants } from "../_constants";
import { siteConfigService } from "../_services";
import { alertActions } from "./";

export const siteConfigActions = {
  getSiteConfig,
  getCategories
};

function getSiteConfig() {
  return dispatch => {
    dispatch(request());

    siteConfigService.getSiteConfig().then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: siteConfigConstants.GET_SITE_CONFIG_REQUEST };
  }
  function success(response) {
    return { type: siteConfigConstants.GET_SITE_CONFIG_SUCCESS, response };
  }
}

function getCategories() {
  return dispatch => {
    dispatch(request());

    siteConfigService.getCategories().then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: siteConfigConstants.GET_CATEGORIES_REQUEST };
  }
  function success(response) {
    return { type: siteConfigConstants.GET_CATEGORIES_SUCCESS, response };
  }
}
