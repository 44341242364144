import { productsConstants } from "../_constants";
import { productsService } from "../_services";
import { alertActions } from "./";

export const productsActions = {
  getQuizItems,
  getProduct,
  getRecommendations,
  getPaginatedRecommendations,
  getShopperRecommendations,
  clearRecommendations,
  getSimilar,
  setQuickview,
  postLike,
  postDislike,
  deleteLike,
  deleteDislike,
  addSessionLike,
  addSessionDislike,
  removeSessionLike,
  removeSessionDislike,
};

function getQuizItems(category) {
  return (dispatch) => {
    dispatch(request());
    productsService.getQuizItems(category).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.QUIZ_ITEMS_REQUEST };
  }
  function success(data) {
    return { type: productsConstants.QUIZ_ITEMS_SUCCESS, data };
  }
  function failure(error) {
    return { type: productsConstants.QUIZ_ITEMS_FAILURE, error };
  }
}

function getRecommendations(category) {
  return (dispatch) => {
    dispatch(request());
    productsService.getRecommendations(category).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.RECOMMENDATIONS_REQUEST, page: 0 };
  }
  function success(recommendations) {
    return { type: productsConstants.RECOMMENDATIONS_SUCCESS, recommendations };
  }
  function failure(error) {
    return { type: productsConstants.RECOMMENDATIONS_FAILURE, error };
  }
}

function getProduct(id) {
  return (dispatch) => {
    dispatch(request());
    productsService.getProduct(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.GET_PRODUCT_REQUEST };
  }
  function success(product) {
    return { type: productsConstants.GET_PRODUCT_SUCCESS, product };
  }
  function failure(error) {
    return { type: productsConstants.GET_PRODUCT_FAILURE, error };
  }
}

function getSimilar(url) {
  return (dispatch) => {
    dispatch(request());
    productsService.getSimilar(url).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.GET_SIMILAR_REQUEST };
  }
  function success(data) {
    return { type: productsConstants.GET_SIMILAR_SUCCESS, data };
  }
  function failure(error) {
    return { type: productsConstants.GET_SIMILAR_FAILURE, error };
  }
}

function getPaginatedRecommendations(page = 1, category) {
  return (dispatch) => {
    dispatch(request(page));
    productsService.getPaginatedRecommendations(page, category).then(
      (data) => {
        dispatch(
          success({
            ...data,
            page,
          })
        );
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(page) {
    return { type: productsConstants.RECOMMENDATIONS_REQUEST, page };
  }
  function success(data) {
    return { type: productsConstants.RECOMMENDATIONS_PAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: productsConstants.RECOMMENDATIONS_FAILURE, error };
  }
}

function getShopperRecommendations(slug, page = 1) {
  return (dispatch) => {
    dispatch(request());
    productsService.getShopperRecommendations(slug, page).then(
      (data) => {
        dispatch(
          success({
            ...data,
            page,
          })
        );
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.SHOPPER_RECOMMENDATIONS_REQUEST };
  }
  function success(data) {
    return {
      type: productsConstants.RECOMMENDATIONS_PAGE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return { type: productsConstants.RECOMMENDATIONS_FAILURE, error };
  }
}

function clearRecommendations() {
  return (dispatch) => {
    dispatch({ type: productsConstants.RECOMMENDATIONS_CLEAR });
  };
}

function getCategories() {
  return (dispatch) => {
    dispatch(request());
    productsService.getCategories().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: productsConstants.CATEGORIES_REQUEST };
  }
  function success(categories) {
    return { type: productsConstants.CATEGORIES_SUCCESS, categories };
  }
  function failure(error) {
    return { type: productsConstants.CATEGORIES_FAILURE, error };
  }
}

function postLike(id) {
  return (dispatch) => {
    dispatch(request());

    productsService.postLike(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: productsConstants.LIKE_REQUEST };
  }
  function success(response) {
    return { type: productsConstants.LIKE_SUCCESS, response, id };
  }
  function failure(error) {
    return { type: productsConstants.LIKE_FAILURE, error };
  }
}

function postDislike(id) {
  return (dispatch) => {
    dispatch(request());

    productsService.postDislike(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: productsConstants.DISLIKE_REQUEST };
  }
  function success(response) {
    return { type: productsConstants.DISLIKE_SUCCESS, response, id };
  }
  function failure(error) {
    return { type: productsConstants.DISLIKE_FAILURE, error };
  }
}

function deleteLike(id) {
  return (dispatch) => {
    dispatch(request());

    productsService.deleteLike(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: productsConstants.DELETE_LIKE_REQUEST };
  }
  function success(response) {
    return { type: productsConstants.DELETE_LIKE_SUCCESS, response, id };
  }
  function failure(error) {
    return { type: productsConstants.DELETE_LIKE_FAILURE, error };
  }
}

function deleteDislike(id) {
  return (dispatch) => {
    dispatch(request());

    productsService.deleteDislike(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: productsConstants.DELETE_DISLIKE_REQUEST };
  }
  function success(response) {
    return { type: productsConstants.DELETE_DISLIKE_SUCCESS, response, id };
  }
  function failure(error) {
    return { type: productsConstants.DELETE_DISLIKE_FAILURE, error };
  }
}

function setQuickview(item) {
  return { type: productsConstants.SET_QUICKVIEW, item };
}

function addSessionLike(id) {
  return { type: productsConstants.ADD_SESSION_LIKE, id };
}

function addSessionDislike(id) {
  return { type: productsConstants.ADD_SESSION_DISLIKE, id };
}

function removeSessionLike(id) {
  return { type: productsConstants.REMOVE_SESSION_LIKE, id };
}

function removeSessionDislike(id) {
  return { type: productsConstants.REMOVE_SESSION_DISLIKE, id };
}
