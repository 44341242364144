import React from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ContactForm } from "../../components/Forms/contact-form";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import AddTaggBanner from "../../components/AddTaggBanner";

const ContactUs = () => {
  return (
    <>
      <SiteNav />
      <Container fluid>
        <Row className="m-0">
          <Col xs={12}>
            <ContactForm />
          </Col>
        </Row>
        <AddTaggBanner />
        <Footer />
      </Container>
    </>
  );
};

export default ContactUs;
