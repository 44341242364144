export const userConstants = {
  GET_USER_REQUEST: "USER_GET_USER_REQUEST",
  GET_USER_SUCCESS: "USER_GET_USER_SUCCESS",
  GET_USER_FAILURE: "USER_GET_USER_FAILURE",

  GET_SHOPPER_REQUEST: "USER_GET_SHOPPER_REQUEST",
  GET_SHOPPER_SUCCESS: "USER_GET_SHOPPER_SUCCESS",
  GET_SHOPPER_FAILURE: "USER_GET_SHOPPER_FAILURE",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",

  VERIFY_SIGNUP_REQUEST: "USER_VERIFY_SIGNUP_REQUEST",
  VERIFY_SIGNUP_SUCCESS: "USER_VERIFY_SIGNUP_SUCCESS",
  VERIFY_SIGNUP_FAILURE: "USER_VERIFY_SIGNUP_FAILURE",

  ADD_PROFILE_PIC_REQUEST: "USER_ADD_PROFILE_PIC_REQUEST",
  ADD_PROFILE_PIC_SUCCESS: "USER_ADD_PROFILE_PIC_SUCCESS",
  ADD_PROFILE_PIC_FAILURE: "USER_ADD_PROFILE_PIC_FAILURE",
  REMOVE_PROFILE_PIC: "USER_REMOVE_PROFILE_PIC",

  UPDATE_USER_REQUEST: "USER_UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "USER_UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "USER_UPDATE_USER_FAILURE",

  RESEND_CODE_REQUEST: "USER_RESEND_CODE_REQUEST",
  RESEND_CODE_SUCCESS: "USER_RESEND_CODE_SUCCESS",
  RESEND_CODE_FAILURE: "USER_RESEND_CODE_FAILURE",

  FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  CHANGE_PASSWORD_REQUEST: "USER_CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "USER_CHANGE_PASSWORD_FAILURE",

  SET_FIRSTNAME: "USER_SET_FIRSTNAME",
  SET_LASTNAME: "USER_SET_LASTNAME",
  SET_EMAIL: "USER_SET_EMAIL",
  SET_BIRTHDAY: "USER_SET_BIRTHDAY",
  SET_USERNAME: "USER_SET_USERNAME",
  SET_REDUX_PW: "USER_SET_REDUX_PW",

  UPDATE_FIRSTNAME: "USER_UPDATE_FIRSTNAME",
  UPDATE_LASTNAME: "USER_UPDATE_LASTNAME",
  UPDATE_EMAIL: "USER_UPDATE_EMAIL",
  UPDATE_BIRTHDAY: "USER_UPDATE_BIRTHDAY",
  UPDATE_SLUG: "USER_UPDATE_SLUG",
  UPDATE_REDUX_PW: "USER_UPDATE_REDUX_PW",

  LOGOUT: "USER_LOGOUT",
  CLEAR_STORE: "USER_CLEAR_STORE",
  CLEAR_ERRORS: "USER_CLEAR_ERRORS"
};
