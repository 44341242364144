import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { ReactComponent as BlackSearch } from "../../../assets/svg/search.svg";
import { ReactComponent as ArrowRight } from "../../../assets/svg/arrow-right.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as Close } from "../../../assets/svg/xclose.svg";

import "./styles.css";

const MobileCategories = ({
  showMenu,
  setShowMenu,
  categories,
  showSearch,
  setShowSearch,
  setCurrentSection,
  searchTerm,
  setSearchTerm,
  showSearchTerm,
  setShowSearchTerm,
}) => {
  const handleKeyPress = (e) => {
    setShowSearch(true);
  };

  return (
    <>
      {showMenu && (
        <div className="menu-overlay">
          <div className="overlay-content">
            {categories.map((category, index) => (
              <span key={index}>
                {(() => {
                  switch (category.id) {
                    case 6:
                      return (
                          <Link
                              to={"/home/tagged"}
                              onClick={() => {
                                setShowMenu(false);
                                setShowSearch(false);
                                setShowSearchTerm(false);
                              }}
                          >
                            <Col xs={12}>
                              <h6 className="mobile-category">
                                {category.categoryName}
                                <ArrowRight className="mobile-arrow" />
                              </h6>
                            </Col>
                          </Link>
                      );
                    case 0:
                      return (
                        <Link
                          to={"/home"}
                          onClick={() => {
                            setShowMenu(false);
                            setShowSearch(false);
                            setShowSearchTerm(false);
                          }}
                        >
                          <Col xs={12}>
                            <h6 className="mobile-category">
                              {category.categoryName}
                              <ArrowRight className="mobile-arrow" />
                            </h6>
                          </Col>
                        </Link>
                      );
                    default:
                      return (
                        <Link
                          to={`/home/category/${category.id}`}
                          onClick={() => {
                            setShowMenu(false);
                            setShowSearch(false);
                            setShowSearchTerm(false);
                          }}
                        >
                          <Col xs={12}>
                            <h6 className="mobile-category">
                              {category.categoryName}
                              <ArrowRight className="mobile-arrow" />
                            </h6>
                          </Col>
                        </Link>
                      );
                  }
                })()}
                <hr className="mobile-divider" />
              </span>
            ))}
          </div>
        </div>
      )}

      <Navbar fixed="top" className="search-container visibledevice">
        <Container>
          <Row
            className="m-0 p-0 align-items-center"
            style={{ width: "100%", height: "30px" }}
          >
            {!showSearchTerm ? (
              <>
                <BlackSearch
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setShowSearchTerm(true);
                  }}
                />
                <span
                  style={{
                    width: "90%",
                    textAlign: "left",
                    padding: "10px",
                  }}
                  onClick={() => {
                    // setShowSearch(true);
                    setShowSearchTerm(!showSearchTerm);
                  }}
                >
                  SEARCH{" "}
                </span>
              </>
            ) : (
              <>
                <BlackSearch
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setShowSearchTerm(false);
                    setShowSearch(false);
                  }}
                />
                <span style={{ width: "85%" }}>
                  <Form.Control
                    inline
                    autoFocus={true}
                    className="search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                </span>
                <Close
                  style={{ marginBottom: "3px", height: "10px" }}
                  onClick={() => {
                    setShowSearchTerm(false);
                    setShowSearch(false);
                  }}
                />
              </>
            )}
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

function mapStateToProps(state) {
  const { siteConfig } = state;
  const categories = siteConfig.categories;

  return {
    categories,
  };
}

const connectedMobileCategories = connect(mapStateToProps)(MobileCategories);
export { connectedMobileCategories as MobileCategories };
