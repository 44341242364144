import React, { useState } from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import { questions } from "./questions";

const Faq = () => {
  const qBlock = (question, answer, id) => {
    return (
      <Col md={8}>
        <h3 className="question-text">{question}</h3>
        <hr className="q-divider" />
        <p className="answer-text">{answer}</p>
      </Col>
    );
  };

  return (
    <>
      {" "}
      <SiteNav />
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={12} className="faq-header m-4">
            <h1>Frequently Asked Questions</h1>
          </Col>

          {questions.map((question) =>
            qBlock(question.question, question.answer, question.id)
          )}
        </Row>{" "}
        <Footer />
      </Container>
    </>
  );
};

export default Faq;
