export const productsConstants = {
  QUIZ_ITEMS_REQUEST: "QUIZ_ITEMS_REQUEST",
  QUIZ_ITEMS_SUCCESS: "QUIZ_ITEMS_SUCCESS",
  QUIZ_ITEMS_FAILURE: "QUIZ_ITEMS_FAILURE",

  RECOMMENDATIONS_REQUEST: "RECOMMENDATIONS_REQUEST",
  RECOMMENDATIONS_SUCCESS: "RECOMMENDATIONS_SUCCESS",
  RECOMMENDATIONS_PAGE_SUCCESS: "RECOMMENDATIONS_PAGE_SUCCESS",
  RECOMMENDATIONS_FAILURE: "RECOMMENDATIONS_FAILURE",
  RECOMMENDATIONS_CLEAR: "RECOMMENDATIONS_CLEAR",

  SHOPPER_RECOMMENDATIONS_REQUEST: "SHOPPER_RECOMMENDATIONS_REQUEST",
  SHOPPER_RECOMMENDATIONS_SUCCESS: "SHOPPER_RECOMMENDATIONS_SUCCESS",
  SHOPPER_RECOMMENDATIONS_PAGE_SUCCESS: "SHOPPER_RECOMMENDATIONS_PAGE_SUCCESS",
  SHOPPER_RECOMMENDATIONS_FAILURE: "SHOPPER_RECOMMENDATIONS_FAILURE",
  SHOPPER_RECOMMENDATIONS_CLEAR: "SHOPPER_RECOMMENDATIONS_CLEAR",

  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",

  CATEGORIES_REQUEST: "CATEGORIES_REQUEST",
  CATEGORIES_SUCCESS: "CATEGORIES_SUCCESS",
  CATEGORIES_FAILURE: "CATEGORIES_FAILURE",

  SET_QUICKVIEW: "SET_QUICKVIEW",

  GET_SIMILAR_REQUEST: "GET_SIMILAR_REQUEST",
  GET_SIMILAR_SUCCESS: "GET_SIMILAR_SUCCESS",
  GET_SIMILAR_FAILURE: "GET_SIMILAR_FAILURE",

  DISLIKE_REQUEST: "DISLIKE_REQUEST",
  DISLIKE_SUCCESS: "DISLIKE_SUCCESS",
  DISLIKE_FAILURE: "DISLIKE_FAILURE",

  LIKE_REQUEST: "LIKE_REQUEST",
  LIKE_SUCCESS: "LIKE_SUCCESS",
  LIKE_FAILURE: "LIKE_FAILURE",

  DELETE_DISLIKE_REQUEST: "DELETE_DISLIKE_REQUEST",
  DELETE_DISLIKE_SUCCESS: "DELETE_DISLIKE_SUCCESS",
  DELETE_DISLIKE_FAILURE: "DELETE_DISLIKE_FAILURE",

  DELETE_LIKE_REQUEST: "DELETE_LIKE_REQUEST",
  DELETE_LIKE_SUCCESS: "DELETE_LIKE_SUCCESS",
  DELETE_LIKE_FAILURE: "DELETE_LIKE_FAILURE",

  ADD_SESSION_LIKE: "ADD_SESSION_LIKE",
  ADD_SESSION_DISLIKE: "ADD_SESSION_DISLIKE",
  REMOVE_SESSION_LIKE: "REMOVE_SESSION_LIKE",
  REMOVE_SESSION_DISLIKE: "REMOVE_SESSION_DISLIKE"
};
