import { uiConstants } from "../_constants";

export function ui(
  state = {
    scrolling: false,
    showCallToAction: false
  },
  action
) {
  switch (action.type) {
    case uiConstants.SCROLL_START:
      return {
        ...state,
        scrolling: true
      };
    case uiConstants.SCROLL_STOP:
      return {
        ...state,
        scrolling: false
      };
    case uiConstants.SHOW_CTA:
      return {
        ...state,
        showCallToAction: action.action
      };
    default:
      return state;
  }
}
