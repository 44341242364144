import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import ErrorBox from "./error-box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrangeButton from "../../components/Buttons/OrangeButton";
import "./styles.css";

const VerifyForm = ({
  dispatch,
  loggedIn,
  loading,
  email,
  password,
  codeResent,
  pw,
  errorMessage
}) => {
  const [validated, setValidated] = useState(false);
  const [code, setCode] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [missingEmail, setMissingEmail] = useState(false);
  const [newPw, setNewPw] = useState("");

  const verificationObject = {
    email: email || newEmail,
    code: code,
    password: password || newPw
  };

  const handleSubmit = event => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else dispatch(userActions.verify(verificationObject));
  };

  const handleErrors = () => {
    if (errorMessage === "Unauthorized") {
      return "Incorrect Password";
    }
    if (
      errorMessage === "User cannot be confirmed. Current status is CONFIRMED"
    ) {
      return "Account has been confirmed. Please login.";
    }
    if (errorMessage) {
      return "Invalid verification code provided, please try again or resend code";
    } else {
      return null;
    }
  };

  const handleResendCode = () => {
    if (email !== "") {
      dispatch(userActions.resendCode(verificationObject.email));
    } else if (newEmail !== "") {
      dispatch(userActions.resendCode(verificationObject.email));
    } else {
      setMissingEmail(true);
    }
  };

  return (
    <Row className="justify-content-center m-0">
      <Col md={12} className="mt-4">
        <Form
          noValidate
          validated={validated}
          onChange={() => {
            setValidated(false);
          }}
          onClick={() => dispatch(userActions.clearErrors())}
        >
          <Form.Group align="left">
            <Form.Label> 6-digit Verification Code</Form.Label>
            <Form.Control
              required
              as="input"
              onChange={e => setCode(e.target.value)}
              maxlength={6}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your 6-digit verification code.
            </Form.Control.Feedback>
          </Form.Group>
          {email === "" && (
            <Form.Group align="left">
              <Form.Label> Email Address</Form.Label>
              <Form.Control
                required
                as="input"
                onChange={e => setNewEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email.
              </Form.Control.Feedback>
              {missingEmail && (
                <small className="password-hint">
                  Please enter your account email to receive a new verification
                  code.
                </small>
              )}
            </Form.Group>
          )}
          {password === "" && (
            <Form.Group align="left">
              <Form.Label> Password</Form.Label>
              <Form.Control
                required
                as="input"
                type="password"
                onChange={e => setNewPw(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your password.
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {errorMessage && <ErrorBox message={handleErrors()} />}
          <br /> <br />
          <Row className="justify-content-center m-0">
            <Col xs={12} className="mb-3">
              <OrangeButton
                buttonText={loading ? "Loading..." : "Submit"}
                onClick={e => {
                  handleSubmit(e);
                }}
                className="ml-4"
              />
            </Col>

            <Col xs={12}>
              <OrangeButton
                buttonText={"Resend Code"}
                onClick={() => handleResendCode()}
                className="mr-4"
              />
              {codeResent && <p>Code Resent!</p>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  const { authentication, newUser } = state;
  const email = get(newUser, "data.email", "");
  // const pw = get(newUser, "data.pw", "");
  const errorMessage = get(newUser, "verificationError", null);
  const codeResent = get(newUser, "resendCodeSuccess", null);
  const loading = get(newUser, "loading", false);

  const loggedIn = authentication.loggedIn;
  return {
    loggedIn,
    email,
    loading,
    // pw,
    errorMessage,
    codeResent
  };
}

const connectedVerifyForm = connect(mapStateToProps)(VerifyForm);
export { connectedVerifyForm as VerifyForm };
