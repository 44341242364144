import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import get from "lodash/get";

import Col from "react-bootstrap/Col";

import Placeholder from "../../assets/images/gradient-placeholder.jpg";

// import "../../node-modules/brainhubeu/react-carousel/lib/style.css";
import "../../../node_modules/@brainhubeu/react-carousel/lib/style.css";
import "./styles.css";

const ImageBlock = ({ item }) => {
  const [showImg, setShowImg] = useState(false);
  const [imgError, setImgError] = useState(false);

  return (
    <>
      <Col xs={12} className='p-0' style={{ cursor: "pointer" }}>
        <img
          src={showImg && !imgError ? item.image : Placeholder}
          onError={() => setImgError(true)}
          onLoad={() => setShowImg(true)}
          alt='Product1'
          className='similar-looks-image'
        />
      </Col>
    </>
  );
};

function mapStateToProps(state) {
  const { products } = state;
  const loading = get(products, "loading", []);

  return {
    loading,
  };
}

const connectedImageBlock = connect(mapStateToProps)(ImageBlock);
export { connectedImageBlock as ImageBlock };
