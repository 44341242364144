export const questions = [
  {
    id: 1,
    question: "What is Tagg?",
    answer:
      "Hundreds of retailers and thousands of products.  Shopping online has never been more challenging.  Tagg Me curates the top online retailers and selects the perfect products for your unique style. \n\n  Tagg Me selects and partners with online retailers with proven quality and that carry top brands of clothing and accessories - Reword this sentence to be more on brand and less dry. Tagg Me then combines products from a collection of stores allowing you to browse, search, and shop in one simple experience.  Tagg Me is unique to you.  Out of millions of products, Tagg Me curates a boutique of special products selected just for you.",
  },
  {
    id: 2,
    question: "How does Tagg Me work?",
    answer:
      "Tagg Me shops with you and uses machine learning to stock your personal shop with products you'll love.  Tagg Me collects what you like based on your viewing patterns and purchases when using the Google Chrome Extension to learn your unique style.  Your Tagg Me Shop surfaces items specific to you and allows you to further fine tune what you want to see by liking, disliking, and purchasing items from your Tagg Me Shop. \n\n Users across the world, shopping at the top online retailers, continually uncover the freshest products across all price points, styles, and brands to train the Tagg Me shopping engine.",
  },
  {
    id: 3,
    question: "What does Tagg Me do with my information?",
    answer:
      "Tagg Me does not sell or use your information for anything other than building your style shop. Data collected is specific to products or your limited user profile data.  Please see our Privacy Policy for more detailed information about how we use and store data.",
  },
  {
    id: 4,
    question: "How does Like and Dislike work?",
    answer:
      "Your Tagg Me Shop will include items Tagg Me thinks you will like and that are specific to your style.  As Tagg Me is learning, you may see items you really like and you want to let Tagg Me know.  You can click on the Like icon and Tagg Me will make sure to use the information to further fine tune your recommendations.\n\nFrom time to time, you may see something that just isn’t quite right.  You can click on the Dislike icon to let Tagg Me know, ‘Hey, this isn’t my style.  Don’t show this to me again.’ \n\n Made a mistake? Just click the icons again to deselect them.",
  },
  {
    id: 5,
    question: "How do I improve my Tagg Me recommendations?",
    answer:
      "The best way to improve your recommendations is Like, Dislike, and Purchase items through the Tagg Me Shop.  Additionally, you can install the Google Chrome Extension and use it while you shop at individual retailers.  See the Chrome Extension section for more information.",
  },
  {
    id: 6,
    question: "How do I purchase an item through Tagg?",
    answer:
      "Click on the “Shop Now” button on the item you wish to purchase.  Tagg Me will direct you to the retailers site to purchase the item.",
  },
  {
    id: 7,
    question:
      "I’m having trouble purchasing an item at the Tagg Me partner store. Who do I contact? ",
    answer:
      "Please contact the partner store’s customer service.  Tagg Me does not control the purchase process on partner websites.",
  },
  {
    id: 8,
    question: "How do I share my Tagg Me Shop with a friend?",
    answer:
      "Your Tagg Me Shop has a unique name shown in the upper right of your shop window.  \n\nYou can copy this link and share it with a friend.  Your friend can view items in your shop and purchase through Tagg’s partner stores.  ",
  },
  {
    id: 9,
    question: "What stores does Tagg Me collaborate with? ",
    answer:
      "Tagg Me carefully curates the retailers we partner with and is always looking to add more high quality retailers.  Tagg Me currently partners with top retailers such as Nordstrom, Neiman Marcus, Urban Outfitters, ShopBop, FreePeople, Forever21, Zaful, Fwrd, Gilt, Farfetch, Net-a-Porter, Revolve, and Princess Polly.",
  },
  {
    id: 10,
    question: "How do I get my store to be a part of Tagg?",
    answer: "Please visit our information regarding Partners here",
  },
  {
    id: 11,
    question: "How do I create an account?",
    answer:
      "Click on the “Take the Style Quiz” button on our landing page.  This will take your through the process of creating an account.",
  },
  {
    id: 12,
    question: "What is the Style Quiz?",
    answer:
      "The Style Quiz is an easy to use visual questionnaire that gives Tagg Me some information about your style so we can stock your shop right away!  You will be directed to select items you like from several categories of clothing prior to creating your account.",
  },
  {
    id: 13,
    question: "How do I update my profile?",
    answer:
      "Click on your User Profile picture in the upper right corner of the Tagg Me site.  You can make changes to any field by typing in the field entry box.  Click “Save Changes” when you’re done.",
  },
  {
    id: 14,
    question: "How do I close my account?  ",
    answer:
      "We hate to see you go but you can contact us here to close your account. ",
  },
  {
    id: 15,
    question: "What is the Tagg Me Google Chrome Extension?",
    answer:
      "The Google Chrome extension supercharges your recommendations by shopping with you if you’re visiting the websites of any of our partner retailers. The Chrome extension is only active on our partner websites and collects information about the items you’re viewing, that’s all! \n\n The extension can be used to surface recommendations directly from Tagg Me while shopping on other sites.  You can also Like, Dislike, and view your history directly from the Tagg Me Extension.",
  },
  {
    id: 16,
    question: "I’m having problems installing the Google Chrome Extension",
    answer:
      "Please visit Google’s help page for assistance with the Chrome Extension. ",
  },
  {
    id: 17,
    question: "I’m having trouble using Tagg, who can I contact?",
    answer:
      "We’re here to help!  You can contact us using our contact form or email at support@tagg.me",
  },
];
