import React from "react";
import get from "lodash/get";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import { VerifyForm } from "../../components/Forms/verify-form";
import { userActions } from "../../_actions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";

const Verify = ({ dispatch, loggedIn, password, email }) => {
  return loggedIn ? (
    <Redirect to="/AddTagg" />
  ) : (
    <>
      <SiteNav page="signup" />
      <Container>
        <Row className="justify-content-center">
          <h1>Thanks for signing up!</h1>

          {email === "" || password === "" ? (
            <Col xs={12} className="pt-2">
              <h6>
                Your account hasn't been verified yet. Please check your email{" "}
                {email ? <b>{email}</b> : null} for a 6-digit verification code.
              </h6>
            </Col>
          ) : (
            <Col xs={12} className="pt-2">
              <h6>
                An email with a verification code was sent to <b>{email}</b>.
              </h6>
            </Col>
          )}
          <Col xs={12}>
            <VerifyForm password={password} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  const { authentication, newUser } = state;
  const loggedIn = authentication.loggedIn;
  const email = get(newUser, "data.email", "");
  const errorMessage = get(newUser, "error", "");

  return {
    loggedIn,
    email,
    errorMessage,
  };
}

const connectedVerify = connect(mapStateToProps)(Verify);
export { connectedVerify as Verify };
