import { userConstants } from "../_constants/";

let user = localStorage.getItem("user");
user = JSON.parse(user);

const initialState = user
  ? { loggedIn: true }
  : { loggingIn: false, loggedIn: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        // username: action.user.username
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        error: action.error,
      };
    case userConstants.VERIFY_SIGNUP_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updated: true,
      };

    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        updated: false,
        error: action.error,
      };

    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };

    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        updated: true,
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        updated: null,
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
      };
    default:
      return state;
  }
}
