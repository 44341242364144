import React, { useState, useEffect, useRef } from "react";
import { get, isEmpty } from 'lodash'
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { appConstants } from "../../_constants";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Croppie from "croppie";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit-pencil.svg";

import OrangeButton from "../Buttons/OrangeButton";
import TransparentButton from "../Buttons/TransparentButton";
import "./styles.css";

const ImageForm = ({
  dispatch,
  avatar,
  uploadedImage,
  uploadedThumb,
  error,
  loggedIn
}) => {
  const [file, setFile] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [saving, setSaving] = useState(false);

  const canvasEl = useRef(null);

  useEffect(() => {
    if (avatar !== appConstants.placeholderAvatar) {
      setShowCropper(false);
      setCropper(null);
    }
  }, [avatar]);

  useEffect(() => {
    dispatch(userActions.clearErrors());
  }, []);

  useEffect(() => {
    // on file input change
    if (file) {
      let reader = new FileReader();
      reader.onload = evt => {
        setCropper(
          new Croppie(canvasEl.current, {
            url: evt.target.result,
            viewport: {
              width: 200,
              height: 200,
              type: "circle"
            },
            boundary: {
              width: 300,
              height: 300
            },
            showZoomer: true,
            enableOrientation: true
          })
        );
      };
      reader.readAsDataURL(file);
    } else {
      setCropper(null);
    }
  }, [file]);

  const saveCropped = e => {
    e.preventDefault();
    if (cropper) {
      setSaving(true);
      cropper
        .result("blob")
        .then(blob => dispatch(userActions.addProfilePic(blob)));
    } else {
      setShowCropper(false);
    }
  };
  return (
    <>
      <Col xs={"auto"} align="center">
        <div className="profile-pic-container">
          <img
            src={avatar}
            id="profile-avatar"
            alt="profile-avatar"
            className="profile-picture"
            onClick={() => setShowCropper(true)}
          />
          <div className="middle" onClick={() => setShowCropper(true)}>
            <div className="edit">
              <Edit
                className="edit-icon"
                onClick={() => setShowCropper(true)}
              />
            </div>
          </div>
        </div>

        {error === "InvalidImageFormat" && <p>invalid image format</p>}
        {error && <p>{error}</p>}
      </Col>

      <input
        className="file-input-hidden"
        type="file"
        id="input-image"
        name="image"
        onChange={e => {
          const { files } = e.target;
          if (files[0].type.match(/^image\//)) {
            setFile(files[0]);
          }
        }}
        accept="image/*"
        onClick={error ? () => dispatch(userActions.clearErrors()) : null}
      />

      <Modal
        show={showCropper}
        onHide={() => {
          setShowCropper(false);
          setCropper(null);
        }}
        centered
        // dialogClassName="modal-90w"
      >
        <Modal.Body closeButton>
          <Col md={{ span: 4, offset: 8 }} align="right">
            <Button
              className="btn-icon"
              align="right"
              onClick={() => {
                setShowCropper(false);
                setCropper(null);
              }}
              aria-label="close"
            >
              <XClose />
            </Button>
          </Col>
          <form onSubmit={saveCropped}>
            <Row className="justify-content-center m-0">
              <Col xs={12} align="center">
                <h4 className="category-header" align="center">
                  Update your profile picture
                </h4>
              </Col>
              {!cropper && (
                <>
                  <label for="input-image">
                    <Col xs={12} align="center" className="p-4">
                      <img
                        src={avatar}
                        alt="avatar"
                        className="edit-picture"
                        height={300}
                        width={300}
                      />
                    </Col>
                  </label>
                  <input
                    className="file-input-hidden"
                    id="input-image"
                    type="file"
                    name="image"
                    onChange={e => {
                      const { files } = e.target;
                      if (files[0].type.match(/^image\//)) {
                        setFile(files[0]);
                      }
                    }}
                    accept="image/*"
                  />
                  {/* <span className="file-cta"></span> */}
                </>
              )}
              <div style={{ height: "90%", width: "90%" }} ref={canvasEl}></div>
              <Col xs={12} align="center">
                <TransparentButton
                  buttonText={"Cancel"}
                  onClick={() => {
                    setShowCropper(false);
                    setCropper(null);
                  }}
                  className="btn-decline"
                />

                <OrangeButton
                  disabled={cropper === null}
                  onClick={e => {
                    saveCropped(e);
                  }}
                  buttonText={saving ? "Saving.." : "Save Changes"}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  const { user, authentication } = state;
  const loggedIn = get(authentication, "loggedIn", false);
  let error = get(user, 'error', '');

  // Check if error is an empty object and coerce it to null
  if (typeof error === 'object' && isEmpty(error)) {
    error = null;
  }

  const avatar = get(
    user,
    "userDetails.avatar",
    appConstants.placeholderAvatar
  );

  const uploadedImage = get(
    user,
    "uploadedImage",
    appConstants.placeholderAvatar
  );
  const uploadedThumb = get(
    user,
    "uploadedThumb",
    appConstants.placeholderAvatar
  );

  return { avatar, uploadedImage, uploadedThumb, error, loggedIn };
}

const connectedImageForm = connect(mapStateToProps)(ImageForm);
export { connectedImageForm as ImageForm };
