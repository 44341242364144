import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import thunkMiddleware from "redux-thunk";
// import logger from "redux-logger";
import reducers from "../_reducers";

const persistConfig = {
  // Root?
  key: "root",
  storage,
  blacklist: ["products"]
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose;

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware))
);

let persistor = persistStore(store);

export { store, persistor };
