import { productsConstants, userConstants } from "../_constants";
import pull from "lodash/pull";
const initState = {
  loading: false,
  sessionLikes: [],
  sessionDislikes: []
};

export function likeDislike(state = initState, action) {
  const { sessionLikes = [] } = state;
  const { sessionDislikes = [] } = state;

  switch (action.type) {
    case userConstants.GET_USER_SUCCESS:
      const userLikes = action.data.with.likes;
      const userDislikes = action.data.with.dislikes;
      return {
        ...state,
        sessionLikes: userLikes,
        sessionDislikes: userDislikes
      };
    case productsConstants.LIKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case productsConstants.LIKE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case productsConstants.LIKE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case productsConstants.DISLIKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case productsConstants.DISLIKE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case productsConstants.DISLIKE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case productsConstants.DELETE_LIKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case productsConstants.DELETE_LIKE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case productsConstants.DELETE_LIKE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case productsConstants.DELETE_DISLIKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case productsConstants.DELETE_DISLIKE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case productsConstants.DELETE_DISLIKE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case productsConstants.ADD_SESSION_LIKE:
      return {
        ...state,
        sessionLikes: [...sessionLikes, action.id],
        sessionDislikes: pull(sessionDislikes, action.id)
      };
    case productsConstants.ADD_SESSION_DISLIKE:
      return {
        ...state,
        sessionDislikes: [...sessionDislikes, action.id],
        sessionLikes: pull(sessionLikes, action.id)
      };
    case productsConstants.REMOVE_SESSION_LIKE:
      return {
        ...state,
        sessionLikes: pull(sessionLikes, action.id)
      };
    case productsConstants.REMOVE_SESSION_DISLIKE:
      return {
        ...state,
        sessionDislikes: pull(sessionDislikes, action.id)
      };

    default:
      return state;
  }
}
