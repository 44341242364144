import React from "react";
import Col from "react-bootstrap/Col";
import "./styles.css";

const ErrorBox = ({ message }) => {
  return (
    <>{message !== "" && <Col className="error-message">{message}</Col>}</>
    // <>
    //   {" "}
    //   <Col className="error-message">{message}</Col>
    // </>
  );
};

export default ErrorBox;
