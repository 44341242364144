import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import get from "lodash/get";
import Loader from "../../components/Loader";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { productsActions } from "../../_actions";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow-left.svg";
import Placeholder from "../../assets/images/gradient-placeholder.jpg";
import { ImageBlock } from "./image-block";
// import "../../node-modules/brainhubeu/react-carousel/lib/style.css";
import "../../../node_modules/@brainhubeu/react-carousel/lib/style.css";
import "./styles.css";

const SimilarLooks = ({
  similarProducts,
  loading,
  location,
  quickviewItem,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [showImg, setShowImg] = useState(true);
  const [imgError, setImgError] = useState(false);

  if (loading || quickviewItem === [])
    return (
      <Container>
        <Row className='mt-1 mb-2'>
          <Col md={5} xs={3} className='p-0 my-auto'>
            <hr className='similar-looks' />
          </Col>
          <Col md={2} xs={6} align='center' className='similar-looks my-auto'>
            Similar Looks
          </Col>
          <Col md={5} xs={3} className='p-0 my-auto'>
            <hr className='similar-looks' />
          </Col>
        </Row>
        <Row
          className='align-items-center justify-content-center'
          style={{ height: 140 }}
        >
          <Loader isLoading={loading} />
        </Row>
      </Container>
    );

  return (
    <>
      <Container>
        <Row className='mt-1 mb-2'>
          <Col md={5} xs={3} className='p-0 my-auto'>
            <hr className='similar-looks' />
          </Col>
          <Col md={2} xs={6} align='center' className='similar-looks my-auto'>
            Similar Looks
          </Col>
          <Col md={5} xs={3} className='p-0 my-auto'>
            <hr className='similar-looks' />
          </Col>
        </Row>

        {
          // loading ? (
          //   <Row className='align-items-center justify-content-center'>
          //     <Loader isLoading={loading} />
          //   </Row>
          // ) :
          similarProducts.length > 1 ? (
            <Carousel
              slidesPerPage={
                similarProducts.length < 5 ? similarProducts.length : 5
              }
              breakpoints={{
                1100: {
                  slidesPerPage: 3,
                },
                840: {
                  slidesPerPage: 2,
                },
              }}
              arrowLeft={<ArrowLeft />}
              arrowLeftDisabled={<ArrowLeft />}
              arrowRight={<ArrowRight />}
              arrowRightDisabled={<ArrowRight />}
              addArrowClickHandler
              infinite
            >
              {similarProducts.map((item, index) => (
                <Col
                  key={index}
                  onClick={(e) => {
                    dispatch(productsActions.setQuickview(item));
                    history.replace({
                      pathname: `/qv/${item.itemId || item.objectID}`,
                      state: { background: location },
                    });
                  }}
                >
                  <ImageBlock item={item} />
                </Col>
              ))}
            </Carousel>
          ) : (
            <Row
              className='justify-content-center align-items-center'
              style={{ height: 140 }}
            >
              <p
                style={{
                  fontFamily: "Poppins-reg",
                  color: "#414b55",
                  letterSpacing: "1.5px",
                  align: "center",
                  maxWidth: 500,
                }}
              >
                This item is so unique, there's nothing else like it!
                <div style={{ height: 20 }} />
                <span style={{ fontSize: 10 }}>
                  Yet. TAGG is continually learning to recommend new products
                  that fit your personal style, so check back later for similar
                  looks.
                </span>
              </p>
            </Row>
          )
        }
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  const { products } = state;
  const loading = get(products, "loading", []);
  const quickviewItem = get(products, "quickviewItem", []);

  return {
    loading,
    quickviewItem,
  };
}

const connectedSimilarLooks = connect(mapStateToProps)(SimilarLooks);
export { connectedSimilarLooks as SimilarLooks };
