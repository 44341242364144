import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import lady1 from "../../assets/images/lady1.png";
import lady2 from "../../assets/images/lady2.png";
import lady3 from "../../assets/images/lady3.png";
import Shopbop from "../../assets/store-logos/shopbop@3x.png";
import NeimanMarcus from "../../assets/store-logos/neiman-marcus@3x.png";
import Revolve from "../../assets/store-logos/revolve@3x.png";
import UrbanOutfitters from "../../assets/store-logos/UO-logo@3x.png";
import FreePeople from "../../assets/store-logos/free-people@3x.png";
import Forever21 from "../../assets/store-logos/forever21@3x.png";
import PrincessPolly from "../../assets/store-logos/princess-polly1@3x.png";
import Zaful from "../../assets/store-logos/zaful.png";
import Farfetch from "../../assets/store-logos/farfetch@2x.png";
import Nordstrom from "../../assets/store-logos/Nordstrom@3x.png";
import Netaporter from "../../assets/store-logos/Netaporter@3x.png";
import Fwrd from "../../assets/store-logos/Forward@3x.png";
import Gilt from "../../assets/store-logos/gilt@3x.png";
import BeginningBoutique from "../../assets/store-logos/beginning-boutique@3x.png";
import Shein from "../../assets/store-logos/shein@3x.png";
import Bershka from "../../assets/store-logos/bershka@3x.png";
import Zara from "../../assets/store-logos/zara@3x.png";
import Charcoal from "../../assets/store-logos/charcoal@3x.png";
import Glassons from "../../assets/store-logos/glassons@3x.png";
import VergeGirl from "../../assets/store-logos/verge-girl@3x.png";
import Adika from "../../assets/store-logos/adika@3x.png";
import Akira from "../../assets/store-logos/akira@3x.png";
import PepperMayo from "../../assets/store-logos/peppermayo@3x.png";

import "./styles.css";

const AboutBlock = () => {
  return (
    <Col xs={12} className="brand-ribbon">
      <img src={Shopbop} className="store-logos" alt="shopbop-logo" />

      <img src={NeimanMarcus} className="store-logos" alt="neimans-logo" />
      <img src={Revolve} className="store-logos" alt="Revolve-logo" />

      <img
        src={UrbanOutfitters}
        className="store-logos"
        alt="UrbanOutfitters-logo"
      />

      <img src={FreePeople} className="store-logos" alt="FreePeople-logo" />
      <img src={Forever21} className="store-logos" alt="Forever21-logo" />
      <img
        src={PrincessPolly}
        className="store-logos"
        alt="PrincessPolly-logo"
      />
      <img
        src={
          "https://tagg-lake.s3.amazonaws.com/images/6f7f39b2-c4fd-4546-adc1-23fe868d2626.png"
        }
        className="small-logos"
        alt="Zaful-logo"
      />

      <img src={Farfetch} className="big-logos" alt="farfetch-logo" />

      <img
        src={
          "https://tagg-lake.s3.amazonaws.com/images/0c469a5e-44cd-4c44-a0dd-231c1e6af284.png"
        }
        className="small-logos"
        alt="Asos-logo"
      />
      <img src={Fwrd} className="big-logos" alt="Fwrd-logo" />
      <img src={Nordstrom} className="store-logos" alt="Nordstrom-logo" />
      <img src={Netaporter} className="store-logos" alt="Netaporter-logo" />
      <img src={Gilt} className="big-logos" alt="Gilt-logo" />
      <img
        src={BeginningBoutique}
        className="store-logos"
        alt="BeginningBoutique-Logo"
      />
      <img src={Shein} className="big-logos" alt="Shein-logo" />
      <img src={Bershka} className="big-logos" alt="Bershka-logo" />
      <img src={Zara} className="big-logos" alt="Zara-logo" />
      <img src={Charcoal} className="big-logos" alt="Charcoal-logo" />
      <img src={Glassons} className="big-logos" alt="Glassons-logo" />
      <img src={VergeGirl} className="store-logos" alt="VergeGirl-logo" />
      <img src={Adika} className="store-logos" alt="Adika-logo" />
      <img src={Akira} className="big-logos" alt="Akira-logo" />
      <img src={PepperMayo} className="big-logos" alt="PepperMayo-logo" />
    </Col>
  );
};

export default AboutBlock;
