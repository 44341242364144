import { userService } from "./user.service";
import { authHeader } from "../_helpers";

const TOKEN_REFRESH_INTERVAL = 3000000; // 60 minutes
// const TOKEN_REFRESH_INTERVAL = 3000; // 60 minutes
let timeout = null;

const refreshableFetch = async (url, init, isRetry) => {
  const res = await fetch(url, init);

  if (res.status === 401) {
    if (isRetry) {
      // Already attempted a refresh; logout
      localStorage.clear();
      document.location.href = "/";
    } else {
      // Attempt to refresh the token
      await userService.refreshToken();
      const user = JSON.parse(localStorage.getItem("user"));
      init.headers = {
        ...init.headers,
        ...authHeader(),
      };
      return refreshableFetch(url, init, true);
    }
  }

  return res;
};

export default refreshableFetch;
