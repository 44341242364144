import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import { userActions } from "../../_actions/user.actions";
import get from "lodash/get";
import { ReactComponent as Edit } from "../../assets/svg/edit-pencil.svg";
import { ReactComponent as Check } from "../../assets/svg/orange-check.svg";
import copyUrl from "../../assets/images/copy-url.png";
import { appConstants } from "../../_constants";
import { usernameAvailable, usernameValid } from "../../_constants";

import Form from "react-bootstrap/Form";

import "./styles.css";

const ShopHeader = ({
  greetingName,
  username,
  userDetails,
  avatar,
  dispatch,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const handleNameChange = async () => {
    if (username === userDetails.slug) {
      setShowEdit(false);
    } else {
      const available = await usernameAvailable(userDetails.slug);
      if (
        available &&
        usernameValid(userDetails.slug) &&
        userDetails.slug.length > 1
      ) {
        dispatch(userActions.updateUser({ slug: userDetails.slug }));
        setShowEdit(false);
        setUsernameTaken(false);
        setUsernameInvalid(false);
      } else {
        if (!usernameValid(userDetails.slug)) {
          setUsernameInvalid(true);
        }
        if (!available) {
          setUsernameTaken(true);
        }
      }
    }
  };

  // add enter handling
  let handleKeyPress = function (target) {
    // key is enter
    if (target.charCode == 13) {
      target.preventDefault();
      handleNameChange();
    }
  };

  return (
    <Row className="justify-content-center align-items-center mt-3 mb-0">
      <Col md={5} sm={12} xs={12}>
        <Row className="m-0 justify-content-start" style={{ flexWrap: "nowrap"}}>
          <Col xs={"auto"} className="p-0" align="left">
            <img
              src={avatar}
              className="closet-avatar"
              alt="closet-avatar"
            />
          </Col>
          <Col
            md={"auto"}
            sm={"auto"}
            xs={10}
            className=" my-auto pl-1"
            align="left"
          >
            <h4 align="left" className="greeting mb-0">
              Check out {greetingName}'s style
            </h4>
          </Col>
        </Row>
      </Col>

      <Col md={5} sm={12} xs={12}>
        <Row className="m-0 justify-content-end align-items-center">
          <Col md={"auto"} sm={12} className="p-0 shopper-url my-auto">
            <Form spellCheck={false}>
              <InputGroup
                className={!showEdit ? "mb-3" : "mb-3 input-underline"}
                align="right"
              >
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="shopper-url"
                    className="shopper-url-prefix"
                  >
                    shop.tagg.me/shopper/
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {showEdit ? (
                  <>
                    <Form.Control
                      onKeyPress={handleKeyPress}
                      as="input"
                      className="shop-url"
                      aria-describedby="shopper-url"
                      value={userDetails.slug}
                      autoFocus={true}
                      id="shopper-url-input"
                      onChange={(e) => {
                        dispatch(userActions.updateSlug(e.target.value));
                        setUsernameTaken(false);
                        setUsernameInvalid(false);
                      }}
                    />
                    <Col
                      xs={"auto"}
                      align="left"
                      className="mt-2 pl-1 pt-1"
                    >
                      <Check
                        className="mt-2 ml-1"
                        onClick={() => handleNameChange()}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="shopper-url"
                        className="shopper-url-prefix-slug"
                        onClick={() => setShowEdit(true)}
                      >
                        {username}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Col
                      xs={"auto"}
                      align="left"
                      className="mt-2 pl-1 pt-1"
                    >
                      <Edit
                        className="m-1 mt-2"
                        onClick={() => setShowEdit(true)}
                      />
                      <img
                        src={copyUrl}
                        alt="copy-url"
                        ref={target}
                        className="m-1 mt-2"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://shop.tagg.me/shopper/${userDetails.slug}`
                          );
                          setShowTooltip(!showTooltip);
                        }}
                      />
                      <Overlay
                        target={target.current}
                        show={showTooltip}
                        placement="bottom"
                        onHide={() => setShowTooltip(false)}
                        rootClose
                      >
                        {(props) => (
                          <Tooltip
                            id="overlay-example"
                            className="tooltip"
                            {...props}
                            autohide
                          >
                            Copied!
                          </Tooltip>
                        )}
                      </Overlay>
                    </Col>
                  </>
                )}
              </InputGroup>
            </Form>
          </Col>
          {usernameTaken && (
            <Col xs={12}>
              <p>sorry! this username is taken</p>
            </Col>
          )}
          {usernameInvalid && (
            <Col xs={12}>
              <p>usernames can not contain special characters</p>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  const greetingName = get(user, "greetingName", "");
  const username = get(user, "username", "");
  const userDetails = get(user, "userDetails", {});
  const avatar = get(
    user,
    "userDetails.avatar",
    appConstants.placeholderAvatar
  );

  return { greetingName, username, userDetails, avatar };
}

const connectedShopHeader = connect(mapStateToProps)(ShopHeader);
export { connectedShopHeader as ShopHeader };
