import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Footer from "../Navs/Footer/index.js";
import { SiteNav } from "../Navs/SiteNav/index.js";
import "./styles.css";
import { Quickview } from "./index";

const QuickviewPage = (setShowCta) => {
  return (
    <>
      <SiteNav />
      <Container>
        <Row>
          <Quickview page setShowCta={setShowCta} />
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default QuickviewPage;
