import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { Shopper } from "./pages/Shopper";
import { Profile } from "./pages/Profile";
import { StyleQuiz } from "./pages/StyleQuiz";
import QuickviewModal from "./components/Quickview/quickview-modal";
import QuickviewPage from "./components/Quickview/quickview-page";
import { Signup } from "./pages/Signup";
import { Verify } from "./pages/Signup/verify";
import AddTagg from "./pages/AddTagg";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import Partners from "./pages/Partners";
import Faq from "./pages/Faq";
import About from "./pages/About";
import { Login } from "./pages/Login";

function App() {
  const [password, setPassword] = useState("");
  let location = useLocation();
  let background = location.state && location.state.background;

  return (
    <div className="App">
      <Switch location={background || location}>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/home" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/AddTagg" component={AddTagg} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/Faq" component={Faq} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Privacy" component={PrivacyPolicy} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/Partners" component={Partners} />
        <Route exact path="/Category" component={Home} />
        <Route exact path="/qv/:id" component={QuickviewPage} />
        {/* standalone quickview page ^^ */}
        <Route exact path="/shopper/:slug" component={Shopper} />
        <PrivateRoute exact path="/login" component={Login} />
        <PrivateRoute
          exact
          path="/quiz"
          component={StyleQuiz}
          password={password}
          setPassword={setPassword}
        />
        <PrivateRoute
          exact
          path="/signup"
          component={Signup}
          password={password}
          setPassword={setPassword}
        />
        <PrivateRoute
          exact
          path="/verify"
          component={Verify}
          password={password}
        />
      </Switch>
      {background && (
        <Route
          path="/qv/:id"
          children={<QuickviewModal location={location} />}
        />
      )}
    </div>
  );
}

export default App;
