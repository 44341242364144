import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";
import OrangeButton from "../../components/Buttons/OrangeButton";
import ErrorBox from "../../components/Forms/error-box";
import "./styles.css";

const ForgotPassword = ({ dispatch, loggedIn, email, errorMessage }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [code, setCode] = useState("");
  const [pw, setPw] = useState("");

  const username = { username: email };
  const passwordObj = {
    username: email,
    verification_code: code,
    password: pw
  };
  return loggedIn ? (
    <Redirect to="/home" />
  ) : (
    <Container fluid className="login-box">
      {" "}
      <Row className="p-0">
        <Col md={{ span: 4, offset: 8 }} align="right">
          <Link to="/">
            <Button
              className="btn-icon"
              onClick={() => dispatch(userActions.logout())}
            >
              <XClose />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-center m-4">
        <Col className="quiz-box">
          <Row className="justify-content-md-center ">
            <Col md={12} className="mt-4">
              <h1>Forgot Password</h1>
              <Row className="justify-content-md-center">
                <Col
                  md={12}
                  className="mt-4"
                  onClick={
                    errorMessage ? () => dispatch(alertActions.clear()) : null
                  }
                >
                  <Form.Group align="left">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      as="input"
                      value={email}
                      onChange={e =>
                        dispatch(userActions.setEmail(e.target.value))
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {emailSent && (
                    <>
                      <Form.Group align="left">
                        <Form.Label>6-Digit Verification Code</Form.Label>
                        <Form.Control
                          required
                          as="input"
                          value={code}
                          onChange={e => setCode(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the verification code sent to your email.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group align="left">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          required
                          as="input"
                          type="password"
                          value={pw}
                          onChange={e => setPw(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your password.
                        </Form.Control.Feedback>
                        <small className="password-hint">
                          Password must be at least 8 characters, including
                          lowercase, uppercase, numeric, and special characters.
                        </small>
                      </Form.Group>
                      {errorMessage && <ErrorBox message={errorMessage} />}
                      <br />

                      <OrangeButton
                        className="mt-4"
                        buttonText={"Reset Password"}
                        onClick={() => {
                          dispatch(userActions.resetPassword(passwordObj));
                        }}
                      />
                      <br />
                    </>
                  )}
                  <br />
                  <OrangeButton
                    className="mt-4"
                    buttonText={
                      !emailSent ? "Get Verification Code" : "Resend Code"
                    }
                    onClick={() => {
                      dispatch(userActions.forgotPassword(username));
                      setEmailSent(true);
                    }}
                  />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { newUser, alert } = state;
  const { loggedIn } = state.authentication;
  const email = get(newUser, "data.email", "");
  const errorMessage = get(alert, "message", "");

  return {
    email,
    errorMessage,
    loggedIn
  };
}

const connectedForgotPassword = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };
