import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import get from "lodash/get";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SignupForm } from "../../components/Forms/signup-form";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import "./styles.css";

const Signup = ({ password, setPassword, signupSuccess }) => {
  return signupSuccess ? (
    <Redirect to={"/verify"} />
  ) : (
    <>
      <SiteNav page="signup" />
      <Container fluid>
        <Row className="m-0">
          <Col xs={12}>
            <SignupForm password={password} setPassword={setPassword} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  const { authentication, newUser } = state;
  const loggedIn = authentication.loggedIn;
  const email = get(newUser, "data.email", "");
  const firstName = get(newUser, "data.firstName", "");
  const errorMessage = get(newUser, "error", "");
  const signupSuccess = get(newUser, "signupSuccess", false);

  return {
    loggedIn,
    email,
    firstName,
    errorMessage,
    signupSuccess,
  };
}

const connectedSignup = connect(mapStateToProps)(Signup);
export { connectedSignup as Signup };
