const FormatUtils = {
  /**
   * @name formatPrice
   * 
   * Formats a given price as a USD currency string.
   *
   * @param {string|number|null} price - The price to format. Can be a string or a number.
   * @returns {string|null} The formatted price as a currency string, or null if input is invalid.
   */
  formatPrice: (price) => {
    if (price == null) {
      return null;
    }

    let numericString = price.toString().replace(/[^\d.]/g, '');
    let floatPrice = parseFloat(numericString);

    if (isNaN(floatPrice)) {
      return null;
    }

    var strPrice = floatPrice.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return strPrice;
  }
}

export default FormatUtils;