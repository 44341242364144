import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { QuizCard } from "../../components/QuizCard";

import "./styles.css";

const QuizGrid = ({ quizItems, isLoading }) => {
  const placeholderItem = {};

  return (
    <Container fluid>
      <Row className="mt-1">
        {isLoading
          ? [...Array(9)].map((elementInArray, index) => (
              <QuizCard temp item={placeholderItem} index={0} />
            ))
          : quizItems
              .slice(0, 9)
              .map((item, index) => (
                <>{<QuizCard item={item} index={index} />}</>
              ))}
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { products } = state;
  const isLoading = products.loading;
  return { products, isLoading };
}

const connectedQuizGrid = connect(mapStateToProps)(QuizGrid);
export { connectedQuizGrid as QuizGrid };
