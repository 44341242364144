import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({
  component: Component,
  password,
  setPassword,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} password={password} setPassword={setPassword} />
      )}
    />
  );
}

export default PrivateRoute;
