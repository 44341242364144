import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";
import fetch from "./refreshableFetch";

export const siteConfigService = {
  getSiteConfig,
  getCategories,
};

async function getSiteConfig() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.apiUrl}settings`, requestOptions).then(
    handleResponse
  );
}

async function getCategories() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.apiUrl}categories`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    return data;
  });
}
