import { combineReducers } from "redux";
import { userConstants } from "../_constants/";
import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { user } from "./user.reducer";
import { newUser } from "./new-user.reducer";
import { products } from "./products.reducer";
import { likeDislike } from "./like-dislike.reducer";
import { siteConfig } from "./site-config.reducer";
import { ui } from "./ui.reducer";

const appReducer = combineReducers({
  alert,
  authentication,
  siteConfig,
  newUser,
  user,
  products,
  likeDislike,
  ui
});

const allReducers = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default allReducers;
