import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { productsActions } from "../../_actions/products.actions";
import ProductGrid from "../../components/ProductGrid";

import get from "lodash/get";
import "./styles.css";
import { uiActions } from "../../_actions";

const CategoryPage = ({ dispatch, recommendations, categories }) => {
  let { categoryId } = useParams();
  const category = categories.find((cat) => cat.id === parseInt(categoryId));

  const [recs, setRecs] = useState([]);

  const loadMore = (page) => {
    dispatch(
      productsActions.getPaginatedRecommendations(page, category.categoryFilter)
    );
    setSlice(slice + 20);
  };

  const loadPage = (page) => {
    setSlice(slice + 16);
  };

  const [slice, setSlice] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSlice(0);
    dispatch(productsActions.clearRecommendations());
    dispatch(
      productsActions.getPaginatedRecommendations(1, category.categoryFilter)
    );

  }, [category]);

  return (
      <Row className='ml-1 mr-1 justify-content-center'>
        <Col
          sm={12}
          xl={6}
          className='align-self-center'
        >
          <h4 className='category-header'>{category.categoryName}</h4>
        </Col>
        <ProductGrid
          shopItems={recommendations}
          loadMore={loadPage}
          slice={slice}
        />
      </Row>
  );
};

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { products, user, siteConfig } = state;
  const recommendations = get(products, "recommendations", []);
  const categories = get(siteConfig, "categories", []);
  const loading = get(user, "loading", false);
  const recPage = products.page;

  return {
    loggedIn,
    recommendations,
    categories,
    loading,
    recPage,
  };
}

const connectedCategoryPage = connect(mapStateToProps)(CategoryPage);
export { connectedCategoryPage as CategoryPage };
