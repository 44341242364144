import React from "react";

import "./styles.css";

const Loader = ({ isLoading }) => {
  return (
    <div className={`loader-wrapper${isLoading ? " is-active" : ""}`}>
      <div className="loader"></div>
    </div>
  );
};
export default Loader;
