import { userConstants } from "../_constants";

export function newUser(
  state = {
    loading: false,
    signupSuccess: false,
    verifySuccess: false,
    error: null,
    data: {}
  },
  action
) {
  switch (action.type) {
    case userConstants.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        signupSuccess: false,
        signupError: null
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        signupSuccess: true
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        signupSuccess: false,
        signupError: action.error
      };
    case userConstants.VERIFY_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        verificationError: null
      };
    case userConstants.VERIFY_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        verifySuccess: true
      };
    case userConstants.VERIFY_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        signupSuccess: false,
        verificationError: action.error
      };
    case userConstants.RESEND_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        resendCodeSuccess: false
      };
    case userConstants.RESEND_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        resendCodeSuccess: true
      };
    case userConstants.RESEND_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        resendCodeSuccess: false,
        resendCodeError: action.error
      };
    case userConstants.SET_FIRSTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.firstName
        }
      };
    case userConstants.SET_LASTNAME:
      return {
        ...state,
        data: {
          ...state.data,
          lastName: action.lastName
        }
      };
    case userConstants.SET_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.email
        }
      };
    case userConstants.SET_BIRTHDAY:
      return {
        ...state,
        data: {
          ...state.data,
          birthday: action.birthday
        }
      };
    case userConstants.SET_USERNAME:
      return {
        ...state,
        data: {
          ...state.data,
          username: action.username
        }
      };
    case userConstants.SET_REDUX_PW:
      return {
        ...state,
        data: {
          ...state.data,
          pw: action.pw
        }
      };
    case userConstants.CLEAR_ERRORS:
      return {
        ...state,
        signupError: null,
        verificationError: null,
        resendCodeError: null,
        resendCodeSuccess: null
      };
    default:
      return state;
  }
}
