import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { userActions } from "../../_actions";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Image } from "react-bootstrap";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";
import OrangeButton from "../../components/Buttons/OrangeButton";
import Logo from "../../assets/images/new-logo.svg";
import { VerifyForm } from "../../components/Forms/verify-form";

import "./styles.css";

const Login = ({ dispatch, loggingIn, loggedIn, error, email }) => {
  const username = email;
  const [password, setPassword] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(userActions.login(username, password));
    }
  };

  return loggedIn ? (
    <Redirect to="/home" />
  ) : (
    <Container fluid className="login-box">
      <Row className="p-0 mb-0">
        <Col md={{ span: 4, offset: 8 }} align="right">
          <Link to="/">
            <Button className="btn-icon">
              <XClose onClick={() => dispatch(userActions.logout())} />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-center  m-4 vh-100">
        <Col>
          <Image style={{ margin: "50px", width: "200px" }} src={Logo} />
          <h1>Log In</h1>
          <p>
            Don't have an account? <Link to="/quiz">Sign up!</Link>
          </p>
          <Row className="justify-content-md-center mt-0">
            <Col md={12} className="mt-4">
              <Form.Group align="left">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  as="input"
                  value={email}
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  onChange={(e) =>
                    dispatch(userActions.setEmail(e.target.value))
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group align="left">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  as="input"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onClick={error && (() => dispatch(userActions.clearErrors()))}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your password.
                </Form.Control.Feedback>
              </Form.Group>{" "}
              {(error === "InvalidLogin" || error === "BadRequest") &&
                "Unable to log in. Please check your username and password."}
              {error === "UserNotVerified" && <Redirect to="/verify" />}
              <br />
              <br />
              <OrangeButton
                className="mt-4"
                buttonText={loggingIn ? "Logging in…" : "Log In"}
                onClick={() => dispatch(userActions.login(username, password))}
              />
              <br />
              <br />
              <Link to="/ForgotPassword">Forgot password?</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn, error } = state.authentication;
  const { newUser } = state;
  const email = get(newUser, "data.email", "");

  return {
    loggingIn,
    loggedIn,
    email,
    error,
  };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };
