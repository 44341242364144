import React from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import AddTaggInstructions from "../../components/AddTaggInstructions";

const TaggExtension = () => {
  return (
    <>
      <SiteNav />
      <Container fluid>
        <AddTaggInstructions />
      </Container>
      <Footer />
    </>
  );
};

export default TaggExtension;
