import React from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";

const PrivacyPolicy = () => {
  return (
    <>
      {" "}
      <SiteNav />
      <Container fluid>
        <br />
        <h4>
          TAGG
          <br />
          Privacy Policy
        </h4>

        <div align="left">
          <p>
            This Privacy Policy describes the practices of Tagg.me (“TAGG”)
            regarding collection, use, and disclosure of user information. By
            using any TAGG mobile application (“App”) and any TAGG website
            (collectively, the “Online Services”), you consent to the
            information, collection, and handling practices outlined in this
            Privacy Policy.
          </p>
          <ol>
            <li>
              Information Collection <br />
              <br />
              <p>
                1.1. TAGG may collect user information, both personal and
                non-personal, through our Online Services. The information is
                gathered from your usage of the Online Services, as well as from
                your entry of information in our Online Services. This collected
                information consists of two basic types of data: 1) personal
                information and 2) non-personal information. Personal
                information is information that can be used to uniquely identify
                you as an individual or contact you directly. This may include
                contact information such as your name, address, telephone
                number, date of birth, and email address, as well as driver’s
                license number or vehicle year, make, model and license plate.
                Personal information may also include sensitive information such
                as income amounts, payment data, credit records, social security
                numbers, and account numbers. We also collect certain
                information about your shopping including:
              </p>
              <ul>
                <li>
                  <p>
                    Purchase information, such as the items you purchase, items
                    you have altered or custom made (including measurements),
                    your shipping address and contact information (such as for
                    receipts or order updates); and
                  </p>
                </li>
                <li>
                  <p>
                    Profile and preference information, which may include
                    information you provide to TAGG about your favorite styles,
                    information about items you have chosen as compatible with
                    your style (even though you did not make a purchase), items
                    in your online shopping cart or saved for future purchase,
                    registry information, and product review information.
                  </p>
                </li>
              </ul>
              <p>
                Most of this information is only collected based upon your
                choice to provide it to TAGG. You have the option to choose not
                to provide personal information to us; however, the lack of some
                personal information may prevent TAGG from providing certain
                requested services.{" "}
              </p>
              <p>
                1.2. Non-personal information is general information that does
                not permit direct association with you as an individual. When
                you use our Online Services, we may automatically collect
                non-personal information such as your IP address, device
                information, browser, internet connection speed, web pages
                visited, and your link selections on our various website pages
                or within an application. We collect certain data when you
                download and use our App, such as App usage data, the date and
                time the App on your device accesses our servers, and what data
                and files have been downloaded to the App based on your device
                number. All of this information may be used to: (1) manage and
                protect our software applications, websites, products, services,
                and users from fraudulent, abusive, or unlawful uses; and (2)
                help us research, develop, and improve our software
                applications, websites, products, and services. This type of
                information may also be aggregated or otherwise rendered
                anonymous for business and marketing uses by us or by third
                parties.
              </p>
            </li>
            <li>
              Use and Disclosure of Information
              <br />
              <br />
              <p>
                {" "}
                2.1. We use the information we collect from you to fulfill your
                orders and provide you with products and services we think you
                will like. In particular, we use your information to personalize
                and customize your experience, including to customize the
                products and services we share with you, and to track and
                categorize your activity and interests. We also use your
                information for marketing and advertising, such as to send you
                marketing and advertising communications and to customize the
                marketing and advertising that we show you. We also use your
                information for analytics, such as to understand how you use our
                website and mobile application, understand how you shop with us,
                determine the methods and devices used to access our website and
                mobile application, and improve our website and mobile
                application.
              </p>
              <p>
                2.2. We may share your information within the TAGG family of
                companies and our affiliated companies for operational and
                marketing purposes. The TAGG companies that access your
                information will do so in conformity with the practices
                described in this Privacy Policy. Sharing this information
                allows TAGG to provide you with the best customer service
                experience possible, provide you with the services you have
                ordered or requested, and notify you of the latest information
                regarding new or enhanced products, services, and promotions.
                TAGG may periodically send offers, promotions, and updates
                regarding TAGG products and services to current and potential
                users via e-mail. Every marketing e-mail TAGG sends contains
                instructions and procedures allowing you to opt out of
                additional marketing e-mails for that product or service.{" "}
              </p>
              <p>
                2.3. We may also transfer certain personal information,
                including contact information, but excluding sensitive
                information such as social security or account numbers, to third
                parties for the purpose of providing you with relevant
                promotions and product information, event announcements and
                other marketing communications. This may include when we
                cooperate with other companies to offer joint products and
                services to you, or when our partners sponsor or participate in
                our events. Please note that once personal information is shared
                with another company, the information received by the other
                company becomes subject to its privacy policies and practices.
                You may opt out of these third-party communications by clicking
                the unsubscribe link in any emails received. Similarly, if you
                wish to opt out of having your information disclosed to third
                parties for marketing purposes, please contact us at
                [support@tagg.me].
              </p>
              <p>
                2.4. In some circumstances, we may also use third-party
                companies, agents, and contractors to perform services on our
                behalf, such as application development, website optimization,
                email distribution, processing credit card transactions,
                infrastructure and IT services, background screening, and other
                data analysis, processing, or handling functions. TAGG may share
                your information with these third parties to the extent
                reasonably necessary for the third party to perform the services
                on our behalf.{" "}
              </p>
              <p>
                2.5. TAGG may also disclose user information if we reasonably
                believe that such disclosure is necessary: (1) to satisfy any
                applicable legal or regulatory requirement, (2) to respond to a
                governmental request as required by law such as to comply with a
                subpoena, or similar legal process, (3) to enforce the TAGG
                Terms and Conditions [https://shop.tagg.me/terms] or investigate
                potential violations thereof, (4) to protect against fraudulent,
                malicious, abusive, unauthorized or unlawful use of TAGG
                products, services, and websites, or (5) to protect our rights
                or property, or the safety or security of our customers,
                employees, or data.{" "}
              </p>
              <p>
                2.6. If TAGG enters into any business transition, restructuring,
                merger, sale, or other transferring of assets, we reserve the
                right to transfer user information, including all personal
                information, as part of or in connection with the transaction.
                You will be notified via email and/or a prominent notice on our
                website of any change in ownership or uses of your personal
                information, as well as any choices you may have regarding your
                personal information.
              </p>
            </li>
            <li>
              Cookies, Web Beacons, and Other Technologies <br />
              <br />
              <p>
                {" "}
                3.1. When you access our Online Services, TAGG servers may place
                small text files (“cookies”) on your computer for recordkeeping
                purposes. Among other things, cookies enable TAGG to gather
                information about your activity on our websites, and in
                connection with our services, for the purposes of improving your
                online experience, remembering your preferences and settings,
                delivering advertising to match your preferences and interests,
                and for other similar customization purposes. Cookies allow us
                to collect data such as browser type, time spent on our Online
                Services, pages visited, referring URL, language preferences,
                and other aggregated traffic data. We use the data for security
                purposes, to facilitate navigation, to display data more
                effectively, to collect statistical data, to personalize your
                experience while using the Online Services, and to recognize
                your computer to assist your use of the Online Services. We also
                gather statistical data about use of the Online Services to
                continually improve design and functionality, understand how
                they are used, and assist us with resolving questions. You can
                choose whether or not to accept cookies. If, however, you do not
                accept cookies, you may experience some inconvenience in your
                use of the Online Services.{" "}
              </p>
              <p>
                3.2. In addition to cookies, TAGG may utilize web beacons, clear
                gifs, or other technologies built into our websites, products,
                and services to gather information on how users interact with
                and utilize various features. These technologies can help
                personalize your TAGG usage experience and help associate your
                interactions with our products and services to other information
                within your account. For example, these technologies can
                identify popular pages, viewing patterns, click-through,
                conversion rates, and other information that can be used to
                improve, monitor, and operate our websites, products, and
                services.
              </p>
            </li>
            <li>
              Third-Party Advertisements and Links to Other Sites <br />
              <br />
              <p>
                4.1. Certain TAGG Online Services may include third-party
                advertisements and links to other websites. These linked
                websites are operated by unaffiliated third parties that have
                separate and independent privacy statements, terms of use, and
                related notices or disclosures regarding information handling
                practices. Third-party advertisers may also utilize cookies, web
                beacons, and similar technologies to collect information
                regarding your website interactions and activities. These
                third-party technologies may be utilized by those entities to
                provide targeted advertising and web links based upon your
                activities and preferences. If you choose to interact with
                specific advertisers who advertise on our sites, the information
                you provide to them is subject to the conditions of their
                specific privacy policies and is in no way covered by the
                practices described in this Privacy Policy. TAGG cannot be
                responsible for the information handling practices of
                independent parties and encourages you to review their practices
                prior to information disclosure.
              </p>
            </li>
            <li>
              Social Media Integration <br />
              <br />
              <p>
                5.1. TAGG Online Services may include the option of integration
                through third-party social networking sites and related social
                media plug-ins or applications. When you integrate or link our
                App, websites, products, and services with your social media
                applications (e.g., Facebook, LinkedIn, Google+, etc.), you
                authorize TAGG to have access to certain information from your
                social media profile (e.g., name, e-mail address, photo, gender,
                birthday, location, your list of friends, comments, etc.) to
                deliver the content or as part of the operation of the
                integrated application. We may also obtain non-personally
                identifiable information (e.g., content viewed, advertising
                viewed, etc.) from your interaction with our integrated content.
              </p>
              <p>
                5.2. By choosing to integrate TAGG Online Services with social
                media applications, you consent to the use of this information
                in accordance with our Privacy Policy. When you provide personal
                information to us through an integrated application, it may be
                publicly viewed by other members of these sites, and TAGG cannot
                prevent further use of the information by independent third
                parties. For more information about how social networking sites
                handle your personal information and available privacy settings,
                please refer to their privacy policies and terms of use.
              </p>
            </li>
            <li>
              Location-based Services <br />
              <br />
              <p>
                {" "}
                6.1. TAGG Apps allow access via smartphones and tablet devices,
                with the ability to provide location-based services.{" "}
              </p>
              <p>
                6.2. TAGG Apps may include the option to provide you with
                helpful information based on your location. To provide such
                location-based services, TAGG may collect, use, and share
                precise location data, including the real-time geographic
                location of your mobile device. This location data is collected
                anonymously in a form that does not personally identify you. If
                you wish to deactivate this feature, you can disable the
                location services on your mobile device.
              </p>
            </li>
            <li>
              Specific Opt-outs <br />
              <br />
              <p>
                7.1. Email. You can opt out of marketing or advertising emails
                by using the "unsubscribe" link or mechanism noted in any
                marketing or advertising emails that you receive from us. You
                may also request to opt out of marketing or advertising emails
                by contacting us directly through our customer service contacts.{" "}
              </p>
              <p>
                7.2. Mobile Location. You may choose not to provide your
                location to us in connection with your use of our App by
                declining any prompt requesting location information and by
                customizing your mobile device settings to prevent sharing of
                location information.
              </p>
            </li>
            <li>
              Information Security <br />
              <br />
              <p>
                8.1. TAGG implements and maintains physical, administrative, and
                technical safeguards designed to secure user information and
                prevent unauthorized access to, or disclosure of, that
                information. Under our security policies and practices, access
                to sensitive personal information is authorized only for those
                who have a business need for such access, and sensitive records
                are retained only as long as reasonably necessary for business
                or legal purposes. TAGG strives to protect the user information
                that we collect and store; however, no security program is 100%
                secure, and we cannot guarantee that our safeguards will prevent
                every unauthorized attempt to access, use, or disclose personal
                information. TAGG maintains security-incident response policies
                and procedures to handle incidents involving unauthorized access
                to private information that we collect or store. If you have
                reason to believe that your interaction with us is no longer
                secure (for example, if you feel that the security of your
                account has been compromised), please immediately notify us in
                accordance with the “Contact Us” section below.
              </p>
            </li>
            <li>
              Children’s Online Privacy <br />
              <br />
              <p>
                9.1. TAGG does not target children under the age of 13 for the
                collection of information online.
              </p>
              <p>
                9.2. Our products, services, and websites are not designed to
                attract the attention of, and are not available for use by,
                persons under the age of 13.
              </p>
            </li>
            <li>
              Accessing and Updating Personal Information
              <br />
              <br />
              <p>
                10.1. In the event your personal information is determined to be
                outdated, incomplete, or inaccurate, you may access and update
                your information by logging into your TAGG account. If you
                require verification that your requested changes have been
                completed, and such verification is not readily displayed in
                your product or service account, you may request confirmation at
                the email address listed below. See Contact Us.
              </p>
            </li>
            <li>
              California Privacy Rights – “Shine The Light” Requests <br />
              <br />
              <p>
                11.1. Civil Code Section § 1798.83 permits California residents
                to request certain information regarding our disclosure of
                personal information to third parties for their direct marketing
                purposes.
              </p>
              <p>
                11.2 Your options for making such a request are listed below
                under “Contact Us.”
              </p>
            </li>
            <li>
              Privacy Policy Changes
              <br />
              <br />
              <p>
                12.1 As TAGG develops new products and services, updates or
                expands technologies, and develops new information uses, we
                reserve the right to update this Privacy Policy as necessary to
                reflect such changes. We recommend that you review this policy
                periodically for any changes that may have been made.
              </p>
              <p>
                12.2 Your continued use of TAGG products, services, and websites
                subsequent to any changes made after your initial consent means
                that you agree to be bound by those changes. The most current
                version of this Privacy Policy supersedes all previous versions.
              </p>
            </li>
            <li>
              Contact Us <br />
              <br />
              <p>
                If you have any questions or concerns about the TAGG Privacy
                Policy or its implementation, you may contact us at
                [support@tagg.me] or mail your written inquiry to: <br />
                TAGG
                <br />
                Attn: Privacy (Legal) <br />
                455 Washington avenue, Glencoe IL 60022
              </p>
            </li>
          </ol>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
