import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrangeButton from "../Buttons/OrangeButton";
import Container from "react-bootstrap/Container";
import { ReactComponent as Tagg } from "../../assets/svg/addext.svg";
import { ReactComponent as TaggMobile } from "../../assets/svg/addext-mobile.svg";

import TaggExt from "../../assets/images/tagg-ext.png";

import "./styles.css";

const AddTagExtension = ({ isScrolling }) => {
  return (
    <div className={`add-tagg${isScrolling ? " scrolling" : ""}`}>
      <Container className="add-tagg-container p-0">
        <Col xs={12}>
          <Row className="justify-content-center mt-4 mb-0">
            <Col
              md={6}
              // xs={12}
              className="justify-content-left mt-4 visibledesktop"
              align="left"
            >
              <h3 align="left">Add the Tagg Me Chrome extension!</h3>
              <p align="left" className="small ">
                Simply install the Chrome Extension and Tagg Me will shop with you
                at our affiliated online stores, learning what you like and
                recommending additional products to enhance your shopping
                experience.
              </p>
              <a
                href={
                  "https://chrome.google.com/webstore/detail/mkinfliglhlmjjimggphfijdiikgajmo"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <OrangeButton
                  buttonText={"Get the Extension"}
                  className="mt-2"
                />
              </a>
            </Col>
            <Col md={6} sm={6} xs={12} className="mt-3 pb-4">
              <Tagg className="tagg-svg" />
              <br />
            </Col>
          </Row>
        </Col>
        <hr className="line-long visibledesktop"></hr>
        <hr className="line-long visibledevice mb-4 mt-4"></hr>

        <Col xs={12} className="visibledevice mt-4">
          <h3 align="left">Add the Tagg Me Chrome extension!</h3>
          <p align="left" className="small">
            Simply install the Chrome Extension and Tagg Me will shop with you at
            our affiliated online stores, learning what you like and
            recommending additional products to enhance your shopping
            experience.
          </p>
        </Col>
        <Col xs={12} className="visibledevice" align="left">
          <a
            href={
              "https://chrome.google.com/webstore/detail/mkinfliglhlmjjimggphfijdiikgajmo"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <OrangeButton buttonText={"Get the Extension"} className="mt-2" />
          </a>
        </Col>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isScrolling: get(state, "ui.scrolling", false),
});

export default connect(mapStateToProps)(AddTagExtension);
