import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { get } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { CardReg } from "./card-reg";
import Loader from "../../components/Loader";
import { uiActions } from "../../_actions";
import { withRouter } from "react-router-dom";
import { Quickview } from "../Quickview/index.js";
import reject from "lodash/reject";
import loader from "../../assets/images/loading-gif-transparent-background-loading-gif.gif";

import "./styles.css";
import { filterRecommendations } from "../../_constants";

const ProductGrid = ({
  shopItems,
  dispatch,
  isLoading,
  loadMore = (_) => {},
  page = 0,
  pages = 0,
  quickviewDetails,
  search,
  slice,
}) => {
  const [showQuickview, setShowQuickview] = useState(false);
  const [quickviewItem, setQuickviewItem] = useState("");

  const [slicee, setSlicee] = useState(0);

  const timer = useRef(null);
  const scrollParentRef = useRef(null);

  const handleScroll = (e) => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    let element = e.target,
      distanceFromBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;
    if (distanceFromBottom === 0) {
      timer.current = setTimeout((_) => dispatch(uiActions.scrollStop()), 1000);
    } else {
      dispatch(uiActions.scrollStart());
    }
  };

  const slicedItems = shopItems.slice(0, slice);
  const hasMore = (_) => {
    return slicedItems.length < shopItems.length;
    // if (isLoading) return false;
    // return page < pages || pages === 0;
  };

  const loader = (
    <Loader
      isLoading={isLoading || (isLoading && shopItems && shopItems.length)}
    />
  );

  return search ? (
    <Container fluid className='grid-container' onScroll={handleScroll}>
      <Row className='m-0 mt-4'>
        {shopItems.map((item, index) => (
          <CardReg key={index} item={item} index={index} />
        ))}
      </Row>
      {loader}
    </Container>
  ) : (
    <>
      <Container
        
        className='grid-container'
        // onScroll={handleScroll}
        ref={scrollParentRef}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadMore(page + 1)}
          initialLoad={true}
          hasMore={hasMore()}
          loader={loader}
          useWindow={true}
          threshold={600}
          element='div'
          getScrollParent={() => scrollParentRef}
        >
          <Row className='m-0 mt-4'>
            {slicedItems.map((item, index) => (
              <CardReg key={index} item={item} index={index} />
            ))}
          </Row>
        </InfiniteScroll>
        {loader}
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  const { products } = state;
  const quickviewDetails = get(products, "quickviewItem", {});
  return {
    page: get(products, "page", 0),
    pages: get(products, "pages", 0),
    isLoading: get(products, "loading"),
    quickviewDetails,
  };
}

export default withRouter(connect(mapStateToProps)(ProductGrid));
