// import config from "config";
import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";
import get from "lodash/get";
import refreshableFetch from "./refreshableFetch";

export const userService = {
  login,
  refreshToken,
  getUser,
  getShopper,
  signup,
  verify,
  updateUser,
  addProfilePic,
  resendCode,
  forgotPassword,
  resetPassword,
  changePassword,
  logout,
};

async function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };
  return fetch(`${appConstants.apiUrl}users/me/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user.with));
      localStorage.setItem("user_id", JSON.stringify(user.with.user_id));
    });
}

async function refreshToken() {
  const user = await JSON.parse(localStorage.getItem("user"));
  const refresh_token = user.refresh_token;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh_token }),
  };
  return fetch(`${appConstants.apiUrl}users/me/refreshToken`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user.with));
    });
}

async function getUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return await refreshableFetch(
    `${appConstants.apiUrl}users/${userId}`,
    requestOptions
  ).then(handleResponse);
}

async function getShopper(username) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return await refreshableFetch(
    `${appConstants.apiUrl}users/profile/${username}`,
    requestOptions
  ).then(handleResponse);
}

async function signup(signupObject) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(signupObject),
  };

  return fetch(`${appConstants.apiUrl}users/me`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user.with));
      localStorage.setItem("user_id", JSON.stringify(user.with.user_id));
    });
}

async function verify(verificationObject) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(verificationObject),
  };
  return refreshableFetch(
    `${appConstants.apiUrl}users/me/confirm`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user.with));
      localStorage.setItem("user_id", JSON.stringify(user.with.user_id));
    
      return user;
    });
}

async function addProfilePic(blob) {
  const user = JSON.parse(localStorage.getItem("user"));
  const formData = new FormData();
  formData.append("file", blob);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.access_token,
    },
    body: formData,
  };
  return refreshableFetch(`${appConstants.apiUrl}images`, requestOptions).then(
    handleResponse
  );
}

async function updateUser(userObject) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("user_id"));
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(userObject),
  };
  return await refreshableFetch(
    `${appConstants.apiUrl}users/${userId}`,
    requestOptions
  ).then(handleResponse);
}

async function resendCode(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return refreshableFetch(
    `${appConstants.apiUrl}users/me/code`,
    requestOptions
  ).then(handleResponse);
}

async function forgotPassword(username) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(username),
  };
  return refreshableFetch(
    `${appConstants.apiUrl}users/me/forgotPassword`,
    requestOptions
  ).then(handleResponse);
}

async function resetPassword(passwordObj) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(passwordObj),
  };

  return refreshableFetch(
    `${appConstants.apiUrl}users/me/resetPassword`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user.with));
      localStorage.setItem("user_id", JSON.stringify(user.with.user_id));

      return user;
    });
}

async function changePassword(passwordObj) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    // headers: {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${accessToken}`
    // },

    body: JSON.stringify(passwordObj),
  };
  return refreshableFetch(
    `${appConstants.apiUrl}users/me/changePassword`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("user_id");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        if (data.because === "BadRequest" || data.because === "InvalidLogin") {
          return Promise.reject(data.because);
        } else {
          // logout();
          return Promise.reject(data.because);
        }
      }
      // if (response.status === 400) {
      //   const error = (data && data.because) || response.statusText;
      //   return Promise.reject(error);
      // }
      const error = (data && data.because) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
