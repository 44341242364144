export const appConstants = {
  apiUrl: "https://api.tagg.me/v1/",
  // apiUrl: "http://localhost:3003/v1/",
  placeholderAvatar:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSez5LDFaUWmjfDQPpOd6_5ADptnMUKEkCvMjv5wh5g8b8ccAjD&usqp=CAU",
};

export const usernameAvailable = async (username) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(
    `${appConstants.apiUrl}users/slug/${username}`,
    requestOptions
  );
  const json = await response.json();
  const available = !json.with.exists;

  return available;
};

const usernamePattern = new RegExp("^[a-z0-9-_.]{0,20}$");

export const usernameValid = (username) => {
  if (usernamePattern.test(username)) {
    return true;
  }
};

export const filterRecommendations = (recommendations) => {
  var recs = [];
  recommendations.map((rec) => {
    var http = new XMLHttpRequest();
    http.open("HEAD", rec.image, false);
    http.send();
    if (http.status === 200) {
      recs.push(rec);
    }
  });
  return recs;
};
