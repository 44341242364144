import React from "react";
import Button from "react-bootstrap/Button";
import "./styles.css";

const TransparentButton = ({ buttonText, onClick }) => {
  return (
    <Button className="btn-inverse mt-1" onClick={onClick}>
      {buttonText}{" "}
    </Button>
  );
};

export default TransparentButton;
