import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link, useLocation } from "react-router-dom";
import includes from "lodash/includes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoActive from "../../assets/no-active.png";
import NoInactive from "../../assets/no-inactive.png";
import TaggActive from "../../assets/tagg-active.png";
import TaggInactive from "../../assets/tagg-inactive.png";
import { productsActions, uiActions } from "../../_actions";
import FormatUtils from "../../utils/format";

import "./styles.css";

const CardReg = ({ item, index, likes, dislikes, dispatch, loggedIn }) => {
  
const [isLiked, setLiked] = useState(
  likes.some((like) => like.itemId === item.itemId || like.itemId === item.objectID)
);
const [isDisliked, setDisliked] = useState(
  dislikes.some((dislike) => dislike === item.itemId || dislike === item.objectID)
);

  let location = useLocation();

  const [showImg, setShowImg] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [hideItem, setHideItem] = useState(false);

  useEffect(() => {
    setShowImg(false);
    setImgError(false);
  }, [item]);

  useEffect(() => {
    if (imgError) {
      setHideItem(true);
    }
  }, [imgError]);

  let showImage = false;

  const setShowImage = () => {
    showImage = true;
  };

  return hideItem ? null : (
    // return (
    <Col md={3} sm={4} xs={6} className="mb-2 p-0" key={item.itemId}>
      <Row className="grid-item justify-content-center m-0 p-1">
        <Col xs={12} className="grid-image-container p-0 m-0">
          <Link
            to={{
              pathname: `/qv/${item.itemId || item.objectID}`,
              state: { background: location },
            }}
            onClick={(e) => {
              dispatch(productsActions.setQuickview(item));
            }}
            className="card-link"
          >
            <img
              src={item.image}
              onError={() => {
                console.log("error");
                setHideItem(true);
              }}
              alt=""
              className={isDisliked ? "grid-image-disliked" : "grid-image"}
            />
          </Link>

          {loggedIn && (
            <>
              {isLiked ? (
                <img
                  src={TaggActive}
                  className="tagg-icon"
                  onClick={() => {
                    setLiked(false);
                    dispatch(
                      productsActions.deleteLike(item.objectID || item.itemId)
                    );
                    dispatch(
                      productsActions.removeSessionLike(
                        item.objectID || item.itemId
                      )
                    );
                  }}
                />
              ) : (
                <>
                  <img
                    src={TaggInactive}
                    className="tagg-icon"
                    onClick={() => {
                      setLiked(true);
                      dispatch(
                        productsActions.postLike(item.objectID || item.itemId)
                      );
                      dispatch(
                        productsActions.addSessionLike(
                          item.objectID || item.itemId
                        )
                      );
                    }}
                  />
                </>
              )}

              {isDisliked ? (
                <img
                  src={NoActive}
                  className="no-icon"
                  onClick={() => {
                    setDisliked(false);
                    dispatch(
                      productsActions.deleteDislike(
                        item.objectID || item.itemId
                      )
                    );
                    dispatch(
                      productsActions.removeSessionDislike(
                        item.objectID || item.itemId
                      )
                    );
                  }}
                />
              ) : (
                <img
                  src={NoInactive}
                  className="no-icon"
                  onClick={() => {
                    setDisliked(true);
                    dispatch(
                      productsActions.postDislike(item.objectID || item.itemId)
                    );
                    dispatch(
                      productsActions.addSessionDislike(
                        item.objectID || item.itemId
                      )
                    );
                  }}
                />
              )}
            </>
          )}

          {!loggedIn && (
            <>
              <img
                src={TaggInactive}
                className="tagg-icon"
                onClick={() => {
                  dispatch(uiActions.showCallToAction(true));
                }}
              />
              <img
                src={NoInactive}
                className="no-icon"
                onClick={() => {
                  dispatch(uiActions.showCallToAction(true));
                }}
              />
            </>
          )}
        </Col>

        <Col md={9} className="p-0" align="center">
          <p className="retailer-name">{item.store}</p>
          <p className="style-name mb-1">{item.name}</p>
          {item.price && (
            <p className="retailer-name">{FormatUtils.formatPrice(item.price)}</p>
          )}
        </Col>
      </Row>
    </Col>
  );
};

function mapStateToProps(state) {
  const { likeDislike, authentication } = state;
  const loggedIn = get(authentication, "loggedIn", false);
  const likes = get(likeDislike, "sessionLikes", []);
  const dislikes = get(likeDislike, "sessionDislikes", []);

  return { likeDislike, likes, dislikes, loggedIn };
}

const connectedCardReg = connect(mapStateToProps)(CardReg);
export { connectedCardReg as CardReg };
