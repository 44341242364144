import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrangeButton from "../Buttons/OrangeButton";
import Container from "react-bootstrap/Container";
import { ReactComponent as Tagg } from "../../assets/svg/addext.svg";
import { ReactComponent as TaggMobile } from "../../assets/svg/addext-mobile.svg";
import ActiveTagg from "../../assets/images/extension-icon-active.png";
import InactiveTagg from "../../assets/images/extension-icon-inactive.png";
import TaggExt from "../../assets/images/tagg-ext.png";

import "./styles.css";

const AddTaggInstructions = ({ isScrolling }) => {
  const partners = [
    { name: "Shopbop", url: "https://www.shopbop.com" },
    { name: "Neiman Marcus", url: "https://www.neimanmarcus.com" },
    { name: "Revolve", url: "https://www.revolve.com" },
    { name: "Urban Outfitters", url: "https://www.urbanoutfitters.com" },
    { name: "Free People", url: "https://www.freepeople.com" },
    { name: "Forever 21", url: "https://www.forever21.com" },
    { name: "Princess Polly", url: "https://www.princesspolly.com" },
    { name: "Zaful", url: "https://www.zaful.com" },
    { name: "Farfetch", url: "https://www.farfetch.com" },
    { name: "Nordstrom", url: "https://www.nordstrom.com" },
    { name: "ASOS", url: "https://www.asos.com" },
    { name: "Net-a-Porter", url: "https://www.net-a-porter.com" },
    { name: "FWRD", url: "https://www.fwrd.com" },
    { name: "Gilt", url: "https://www.gilt.com" },
  ];
  return (
    <div className={`add-tagg${isScrolling ? " scrolling" : ""}`}>
      <Container className="add-tagg-container p-0 mt-4">
        <Col xs={12}>
          <Row className="justify-content-center mt-4 mb-0">
            <Col
              md={6}
              // xs={12}
              className="justify-content-left mt-4 visibledesktop"
              align="left"
            >
              <h3 align="left">Add the Tagg Me Chrome extension!</h3>
              <p align="left" className="small ">
                Tagg’s customized process requires data in order to give you the
                best recommendations specific to your style. <br />
                <br />
                You can help us collect that data before we launch and train our
                recommendation model by using the Tagg Me Chrome extension.
              </p>
              <a
                href={
                  "https://chrome.google.com/webstore/detail/mkinfliglhlmjjimggphfijdiikgajmo"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <OrangeButton
                  buttonText={"Get the Extension"}
                  className="mt-2"
                />
              </a>
            </Col>
            <Col xs={12} className="visibledevice mt-4">
              <h3 align="left">Add the Tagg Me Chrome extension!</h3>
              <p align="left" className="small">
                Simply install the Chrome Extension and Tagg Me will shop with
                you at our affiliated online stores, learning what you like and
                recommending additional products to enhance your shopping
                experience.
              </p>
            </Col>
            <Col xs={12} className="visibledevice" align="left">
              <a
                href={
                  "https://chrome.google.com/webstore/detail/mkinfliglhlmjjimggphfijdiikgajmo"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <OrangeButton
                  buttonText={"Get the Extension"}
                  className="mt-2"
                />
              </a>
            </Col>
            <Col md={6} sm={6} xs={12} className="mt-3 pb-4">
              <Tagg className="tagg-svg" />
              {/* <img src={TaggExt} className="tagg-svg" /> */}

              <br />
            </Col>
          </Row>
          <br className="visibledesktop" />
          <hr className="line-long visibledesktop"></hr>
          <br className="visibledesktop" />
          <Row className="justify-content-center">
            {" "}
            <Col md={4} xs={9} className="shadow-box my-auto p-4 mb-3">
              <h3 className="mt-3" align="left">
                How to get started:
              </h3>
              <p className="small mt-3" align="left">
                1. Visit{" "}
                <a
                  href={"https://shop.tagg.me/signup"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  shop.tagg.me/signup
                </a>{" "}
                and create an account. <br />
                <br />
                2.{" "}
                <a
                  href={
                    "https://chrome.google.com/webstore/detail/mkinfliglhlmjjimggphfijdiikgajmo"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add the extension
                </a>
                .
                <br />
                <br />
                3. Visit any of our partner sites to activate the extension.
                <br />
                <br />
                4. Click on the active Tagg Me extension icon on your Chrome
                browser bar and log in.
                <br />
                <br /> 5. Go shopping!{" "}
              </p>
            </Col>
            <Col md={8} className="my-auto">
              {" "}
              <p className="small ml-4 mt-4" align="left">
                The Tagg Me Chrome extension is only activated when visiting one
                of our partner sites. After creating an account and logging in
                through the extension, it will monitor items that you view or
                add to your cart. You can tell if the extension is active by the
                color of the icon on your browser. <br />
              </p>
              <Row className="justify-content-end m-0">
                <Col md={"auto"} xs={6} className="small">
                  <img src={ActiveTagg} alt="active-tagg-icon" />
                  <br />
                  <p className="small" align="center">
                    Extension is active
                  </p>
                </Col>
                <Col md={"auto"} xs={6} className="small">
                  <img src={InactiveTagg} alt="inactive-tagg-icon" />
                  <br />
                  <p className="small" align="center">
                    Extension is inactive
                  </p>
                </Col>
              </Row>
              <Col md={{ span: 11, offset: 1 }} className="justify-content-end">
                <p className="small" align="right">
                  {" "}
                  Visit any of our currently supported partner sites: <br />
                  {partners.map((partner, index) => (
                    <>
                      {index !== 0 && ", "}
                      {index === partners.length - 1 && "or "}
                      <a
                        href={partner.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {partner.name}
                      </a>
                    </>
                  ))}{" "}
                  to activate the extension.
                  <br />
                  <br />
                  We do not collect any other data - you can view our Privacy
                  Policy{" "}
                  <a
                    href={"https://shop.tagg.me/privacy"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                  <br />
                </p>
              </Col>
            </Col>
          </Row>
        </Col>
        <hr className="line-long visibledevice mb-4 mt-4"></hr>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isScrolling: get(state, "ui.scrolling", false),
});

export default connect(mapStateToProps)(AddTaggInstructions);
