import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { SignupForm } from "../../components/Forms/signup-form";
import { VerifyForm } from "../../components/Forms/verify-form";

import { ReactComponent as BackArrow } from "../../assets/svg/back-arrow.svg";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";
import { ReactComponent as Tagg } from "../../assets/svg/addext.svg";

import get from "lodash/get";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import OrangeButton from "../../components/Buttons/OrangeButton";
import { QuizGrid } from "./quizGrid";
import { productsActions, userActions } from "../../_actions";
import "./styles.css";

const StyleQuiz = ({
  password,
  setPassword,
  quizItems,
  dispatch,
  email,
  signupSuccess,
  verifySuccess,
  loading,
}) => {
  const [showExitQuiz, setShowExitQuiz] = useState(false);
  const [quizStep, setQuizStep] = useState(1);

  const categories = [
    { path: "0" },
    { path: "Bottoms", title: "bottoms" },
    { path: "Tops", title: "tops" },
    { path: "Outerwear", title: "outerwear" },
    { path: "Dresses", title: "dresses and rompers" },
    { path: "Swimwear", title: "swimwear" },
    { path: "Accessories", title: "accessories" },
  ];

  useEffect(() => {
    if (categories[quizStep]) {
      dispatch(productsActions.getQuizItems(categories[quizStep].path));
    }
  }, [quizStep]);

  useEffect(() => {
    if (signupSuccess === true) {
      setQuizStep(8);
    }
  }, [signupSuccess]);

  useEffect(() => {
    if (verifySuccess === true) {
      setQuizStep(9);
    }
  }, [verifySuccess]);

  return (
    <Container fluid className='quiz-box'>
      <Row className='justify-content-center m-4'>
        <Col md={12} className='quiz-box'>
          <Row className='mt-0 mb-1'>
            <Col xs={4} align='left' className='pl-0'>
              {quizStep !== 1 && (
                <Button
                  className='btn-icon'
                  onClick={() => setQuizStep(quizStep - 1)}
                >
                  <BackArrow />
                </Button>
              )}
            </Col>
            {quizStep <= 9 && (
              <>
                <Col xs={4} className='my-auto'>
                  <p className='mb-0'>{quizStep} OF 9</p>
                </Col>
                <Col xs={4} align='right' className='pr-0'>
                  <Button
                    className='btn-icon'
                    onClick={() => setShowExitQuiz(true)}
                  >
                    <XClose />
                  </Button>
                </Col>
              </>
            )}
          </Row>

          <Row className='justify-content-md-center m-2'>
            {quizStep <= 6 && (
              <>
                <Col xs={12}>
                  <h1>
                    Which styles of {categories[quizStep].title} do you prefer?
                  </h1>
                  <p>Pick as many as you like</p>
                </Col>
                <Col md={8} xs={12}>
                  <QuizGrid quizItems={quizItems} />
                  <OrangeButton
                    onClick={(e) => {
                      setQuizStep(quizStep + 1);
                    }}
                    buttonText={"Next Step"}
                  />
                </Col>
              </>
            )}
            {quizStep === 7 && (
              <Col md={12} className='mb-4'>
                <SignupForm password={password} setPassword={setPassword} />
              </Col>
            )}
            {quizStep === 8  && (
              <>
                <Col md={12}>
                  <h1>Almost done!</h1>
                </Col>
                {email && (
                  <Col md={12}>
                    <p>
                      Please enter the 6-digit verification code sent to {email}
                      .
                    </p>
                  </Col>
                )}
                <Col md={12} className='mb-4'>
                  <VerifyForm password={password} setPassword={setPassword} />
                </Col>
              </>
            )}
            {quizStep === 9 && (
              <>
                <Col md={7} className='p-0'>
                  <h1>
                    Thanks! We've got some great options picked out for you.
                  </h1>
                  <p>
                    Add the Google Chrome extension to get the most out of Tagg.
                  </p>
                  <br />
                </Col>
                <Col md={12} xs={12} align='center' className='p-0'>
                  <Tagg /> <br />
                  <br />
                </Col>
                <Col md={"auto"}>
                  <Button className='btn-orange btn-quiz mt-4'>
                    Get the extension
                  </Button>
                  {"   "}
                </Col>
                <Col md={"auto"}>
                  <Link to='/home'>
                    <Button className='btn-decline btn-quiz mt-4'>
                      Not right now
                    </Button>
                  </Link>
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Modal
          show={showExitQuiz}
          onHide={() => setShowExitQuiz(false)}
          top
          dialogClassName='modal-90w'
        >
          <Modal.Body closeButton className='p-0'>
            <Col md={{ span: 4, offset: 8 }} align='right'>
              <Button
                className='btn-icon'
                align='right'
                onClick={() => setShowExitQuiz(false)}
              >
                <XClose />
              </Button>
            </Col>
            <Row className='justify-content-center mt-0 p-4'>
              <Col md={12}>
                <h1>Are you sure you want to leave the quiz?</h1>
              </Col>
              <Col md={12} className='mb-4'>
                <p align='center'>
                  You will lose your progress if you decide to exit.
                </p>
              </Col>

              <Col xs={"auto"}>
                <Link to='/' onClick={() => setShowExitQuiz(true)}>
                  <Button
                    className='btn-decline btn-quiz mb-1'
                    onClick={() => dispatch(userActions.logout())}
                  >
                    Yes, exit now
                  </Button>
                </Link>
              </Col>
              <Col xs={"auto"}>
                <Button
                  onClick={() => setShowExitQuiz(false)}
                  className='btn-orange btn-quiz mb-1'
                >
                  No, continue quiz
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { products, newUser } = state;
  const quizItems = get(products, "quizItems", []);
  const email = get(newUser, "data.email", null);
  const loading = get(products, "loading", false);
  const signupSuccess = get(newUser, "signupSuccess", null);
  const verifySuccess = get(newUser, "verifySuccess", null);
  return { quizItems, email, signupSuccess, verifySuccess, loading };
}

const connectedStyleQuiz = connect(mapStateToProps)(StyleQuiz);
export { connectedStyleQuiz as StyleQuiz };
