import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  useParams,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import { productsActions, uiActions } from "../../_actions";
import Container from "react-bootstrap/Container";
import OrangeButton from "../Buttons/OrangeButton";
import { ReactComponent as XClose } from "../../assets/svg/xclose.svg";
import { ReactComponent as TaggIcon } from "../../assets/svg/tagg-icon.svg";
import { ReactComponent as NoIcon } from "../../assets/svg/no-icon.svg";
import { ReactComponent as TaggIconInactive } from "../../assets/svg/tagg-icon-inactive.svg";
import { ReactComponent as NoIconInactive } from "../../assets/svg/no-icon-inactive.svg";
import Placeholder from "../../assets/images/gradient-placeholder.jpg";
import get from "lodash/get";
import includes from "lodash/includes";
import { SimilarLooks } from "../SimilarLooks";
import "./styles.css";
import FormatUtils from "../../utils/format";

const Quickview = ({
  loggedIn,
  quickviewItem,
  quickviewDetails,
  likes,
  dislikes,
  dispatch,
  // location,
  similar,
  page,
  likeDislike,
  setShowCta,
}) => {
  let { id } = useParams();

  const isLiked = includes(likes, id);
  const isDisliked = includes(dislikes, id);

  useEffect(() => {
    if ((quickviewDetails.itemId || quickviewDetails.objectID) !== id) {
      dispatch(productsActions.getProduct(id));
    }
  }, [id]);

  useEffect(() => {
    // if (loggedIn) {
    if (quickviewDetails.url) {
      dispatch(productsActions.getSimilar(quickviewDetails.url));
    }
    // }
  }, [quickviewDetails]);

  let history = useHistory();
  let loc = useLocation();
  let location = loc.state ? loc.state.background : null;

  let back = (e) => {
    if (page) {
      window.close();
    } else {
      e.stopPropagation();
      history.goBack();
    }
  };

  const [showImg, setShowImg] = useState(false);
  const [imgError, setImgError] = useState(false);

  useEffect(() => {
    setShowImg(false);
    setImgError(false);
  }, [quickviewItem]);

  return (
    <Container>
      <Row className='justify-content-center mb-1'>
        <Col md={6} className='visibledesktop' align='center'>
          <img
            src={showImg && !imgError ? quickviewDetails.image : Placeholder}
            onLoad={() => setShowImg(true)}
            onError={() => setImgError(true)}
            alt='item'
            className='main-img'
          />
        </Col>
        <Col md={6}>
          <Row className='m-0 qv-details'>
            <XClose className='x-icon' onClick={back} />
            <Col md={12} className='mt-4'>
              <h1 className='qv-header'>{quickviewDetails.store}</h1>

              <p>{quickviewDetails.name}</p>
            </Col>
            <Col md={12} className='p-0 visibledevice'>
              <img
                src={
                  showImg && !imgError ? quickviewDetails.image : Placeholder
                }
                onLoad={() => setShowImg(true)}
                onError={() => setImgError(true)}
                // src={quickviewDetails.image}
                alt='item'
                className='main-img'
              />
            </Col>
            <Col md={12}>
              <h2 className='qv-price'>
                {quickviewDetails.price &&
                  FormatUtils.formatPrice(quickviewDetails.price)}
              </h2>
              {loggedIn ? (
                <Col xs={12} className='p-0'>
                  <a
                    href={quickviewDetails.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <OrangeButton
                      className='mt-4 mb-4'
                      buttonText={"Shop Now"}
                    />
                  </a>
                  {isLiked ? (
                    <Button
                      className='like-dislike-button-active'
                      onClick={() => {
                        dispatch(productsActions.deleteLike(id));
                        dispatch(productsActions.removeSessionLike(id));
                      }}
                    >
                      <TaggIcon />
                    </Button>
                  ) : (
                    <Button
                      className='like-dislike-button'
                      onClick={() => {
                        dispatch(productsActions.postLike(id));
                        dispatch(productsActions.addSessionLike(id));
                      }}
                    >
                      <TaggIconInactive />
                    </Button>
                  )}
                  {isDisliked ? (
                    <Button
                      className='like-dislike-button-active'
                      onClick={() => {
                        dispatch(productsActions.deleteDislike(id));
                        dispatch(productsActions.removeSessionDislike(id));
                      }}
                    >
                      <NoIcon />
                    </Button>
                  ) : (
                    <Button
                      className='like-dislike-button'
                      onClick={() => {
                        dispatch(productsActions.postDislike(id));
                        dispatch(productsActions.addSessionDislike(id));
                      }}
                    >
                      <NoIconInactive />
                    </Button>
                  )}
                </Col>
              ) : (
                <Col xs={12} className='p-0'>
                  <a
                    href={quickviewDetails.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <OrangeButton
                      className='mt-4 mb-4'
                      buttonText={"Shop Now"}
                    />
                  </a>
                  <div>
                    <Button
                      className='like-dislike-button'
                      onClick={() => {
                        dispatch(uiActions.showCallToAction(true));
                      }}
                    >
                      <TaggIconInactive />
                    </Button>
                    <Button
                      className='like-dislike-button'
                      onClick={() => {
                        dispatch(uiActions.showCallToAction(true));
                      }}
                    >
                      <NoIconInactive />
                    </Button>
                  </div>
                </Col>
              )}
              <br />
              <br />
              <p align='left'>
                <b>Details</b>
                <br />
                {quickviewDetails.description}
              </p>
            </Col>
            {/* {loggedIn && (
              // <Col onClick={backgroundBack}>
              <Col>
                <SimilarLooks similarProducts={similar} />
              </Col>
            )} */}
          </Row>
        </Col>
      </Row>

      <Row className='m-1 p-4 pb-0 mt-auto align-items-end'>
        <SimilarLooks similarProducts={similar} location={location} />
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { products, likeDislike, authentication } = state;
  const loggedIn = authentication.loggedIn;
  const likes = get(likeDislike, "sessionLikes", []);
  const dislikes = get(likeDislike, "sessionDislikes", []);
  const similar = get(products, "similarProducts", []);

  const recommendations = get(products, "recommendations", []);
  const quickviewDetails = get(products, "quickviewItem", {});

  return {
    loggedIn,
    recommendations,
    likes,
    dislikes,
    similar,
    quickviewDetails,
    likeDislike,
  };
}

const connectedQuickview = withRouter(connect(mapStateToProps)(Quickview));
export { connectedQuickview as Quickview };
