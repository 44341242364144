import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { Redirect } from "react-router-dom";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import OrangeButton from "../../components/Buttons/OrangeButton";
import TransparentButton from "../../components/Buttons/TransparentButton";
import ErrorBox from "../../components/Forms/error-box";
import Container from "react-bootstrap/Container";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Footer from "../../components/Navs/Footer/index.js";
import AddTaggBanner from "../../components/AddTaggBanner";
import Jane from "../../assets/images/jane.png";
import { ImageForm } from "../../components/Forms/image-form";
import { ChangePasswordForm } from "../../components/Forms/change-password-form";
import { appConstants } from "../../_constants";

import "./styles.css";

const Profile = ({
  dispatch,
  loggedIn,
  firstName,
  lastName,
  greetingName,
  email,
  birthday,
  avatar,
  avatarThumb,
  uploadedImage,
  uploadedThumb,
  isLoading,
  updated,
  pwUpdated,
  passwordError,
}) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const passwordObj = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  var validPassword =
    "^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,16}$";

  const userObject = {
    email: email,
    name: firstName + " " + lastName,
    birthdate: birthday,
    avatar: uploadedImage || avatar,
    avatarThumb: uploadedThumb || avatarThumb,
  };

  const handleSubmit = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else dispatch(userActions.updateUser(userObject));
  };

  const handlePasswordChange = (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else dispatch(userActions.changePassword(passwordObj));
  };

  const profileCategories = ["account details", "change password"];
  return loggedIn ? (
    <>
      <SiteNav loggedIn={true} />
      <Container fluid className="p-0">
        <Row className="justify-content-center">
          <Col md={6} sm={9} xs={12} className="shadow-box">
            <Row className="my-auto profile-box">
              <Col xs={6} className="my-auto pl-3" align="right">
                <h1>Hi {greetingName}!</h1>
                <hr className="line-left"></hr>
              </Col>
              <Col xs={6} align="left" className="p-0">
                <p className="ml-2 pr-4">
                  Update your account information or change your password.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center m-0 profile-header">
          {profileCategories.map((category, index) => (
            <>
              <Col
                md={2}
                sm={3}
                xs={3}
                className={
                  currentSection === index
                    ? "profile-nav-active"
                    : "profile-nav"
                }
              >
                <Nav.Link
                  onClick={() => {
                    setCurrentSection(index);
                    setValidated(false);
                  }}
                  className="profile-nav"
                >
                  {category}
                </Nav.Link>
              </Col>
            </>
          ))}
        </Row>
        <Row>
          <Container>
            {currentSection === 0 && (
              <>
                <ImageForm />
                <Col xs={12} align="left">
                  <Form
                    noValidate
                    validated={validated}
                    onChange={() => {
                      setValidated(false);
                    }}
                    onClick={
                      updated ? () => dispatch(userActions.clearErrors()) : null
                    }
                  >
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        value={firstName}
                        onChange={(e) =>
                          dispatch(userActions.updateFirstName(e.target.value))
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your first name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group align="left">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        value={lastName}
                        onChange={(e) =>
                          dispatch(userActions.updateLastName(e.target.value))
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your last name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group align="left">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        value={email}
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        onChange={(e) =>
                          dispatch(userActions.updateEmail(e.target.value))
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group align="left">
                      <Form.Label>Birthday</Form.Label>

                      <Form.Control
                        required
                        type="date"
                        value={birthday}
                        pattern="([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))"
                        placeholder="YYYY-MM-DD"
                        onChange={(e) =>
                          dispatch(userActions.updateBirthday(e.target.value))
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        Please enter a valid birthday. (YYYY-MM-DD)
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Col>
                <Col xs={12} align="center" className="mt-4">
                  {updated && <p>Changes saved!</p>}
                  <OrangeButton
                    buttonText={isLoading ? "Saving.." : "Save Changes"}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  />
                </Col>
              </>
            )}
            {currentSection === 1 && (
              <>
                <ChangePasswordForm />
                {/* <Col xs={12} align="left">
                  <Form
                    noValidate
                    validated={validated}
                    onChange={() => {
                      setValidated(false);
                    }}
                    onClick={
                      passwordError
                        ? () => dispatch(userActions.clearErrors())
                        : null
                    }
                  >
                    <Form.Group align="left">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        type="password"
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your current password.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group align="left">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        type="password"
                        pattern={validPassword}
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your new password.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group align="left">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        required
                        as="input"
                        type="password"
                        pattern={newPassword}
                        value={checkPassword}
                        onChange={e => setCheckPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Passwords do not match
                      </Form.Control.Feedback>
                      <small className="password-hint">
                        Password must be at least 8 characters, including
                        lowercase, uppercase, numeric, and special characters.
                      </small>
                    </Form.Group>
                    {passwordError && (
                      <ErrorBox
                        message={
                          "Could not update password. Please verify and try again."
                        }
                      />
                    )}
                  </Form>
                </Col>
                <br />
                <Col xs={12} align="center">
                  {pwUpdated && <p>Password changed!</p>}
                  <OrangeButton
                    buttonText={"Change Password"}
                    onClick={e => handlePasswordChange(e)}
                  />
                </Col> */}
              </>
            )}
            <Col xs={12} align="center">
              {" "}
              <br />
              <TransparentButton
                buttonText={"Log Out"}
                onClick={() => dispatch(userActions.logout())}
              />
            </Col>
          </Container>
        </Row>

        <hr className="black" />
        <AddTaggBanner />
        <Footer />
      </Container>
    </>
  ) : (
    <Redirect to="/" />
  );
};

function mapStateToProps(state) {
  const { authentication, user } = state;
  const passwordError = get(authentication, "error", null);
  const loggedIn = get(authentication, "loggedIn", false);
  const pwUpdated = get(authentication, "updated", false);
  const lastName = get(user, "lastName", "");
  const firstName = get(user, "firstName", "");
  const greetingName = get(user, "greetingName", "");
  const email = get(user, "userDetails.email", "");
  const birthday = get(user, "userDetails.birthdate", "");
  const avatar = get(
    user,
    "userDetails.avatar",
    appConstants.placeholderAvatar
  );
  const avatarThumb = get(
    user,
    "userDetails.avatarThumb",
    appConstants.placeholderAvatar
  );
  const uploadedImage = get(user, "uploadedImage", "");
  const uploadedThumb = get(user, "uploadedThumb", "");
  const isLoading = get(user, "loading", false);
  const updated = get(user, "updated", false);

  return {
    loggedIn,
    passwordError,
    firstName,
    lastName,
    greetingName,
    birthday,
    email,
    uploadedImage,
    uploadedThumb,
    avatar,
    avatarThumb,
    isLoading,
    updated,
    pwUpdated,
  };
}

const connectedProfile = connect(mapStateToProps)(Profile);
export { connectedProfile as Profile };
