import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { productsActions } from "../../_actions";
import GradientPlaceholder from "../../assets/images/gradient-placeholder.jpg";

import "./styles.css";

const QuizCard = ({ item, dispatch, likes, temp }) => {
  // const imageObj = item.meta.openGraph.image;
  // const image = get(imageObj, "url", "");
  const isLiked = likes.includes(item.itemId);
  const [showImg, setShowImg] = useState(false);

  const image = temp ? GradientPlaceholder : item.image;

  const toggleLike = () => {
    !isLiked
      ? dispatch(productsActions.addSessionLike(item.itemId))
      : dispatch(productsActions.removeSessionLike(item.itemId));
  };

  const [imgError, setImgError] = useState(false);
  const [hideItem, setHideItem] = useState(false);

  useEffect(() => {
    setShowImg(false);
    setImgError(false);
  }, [item]);

  useEffect(() => {
    if (imgError) {
      setHideItem(true);
    }
  }, [imgError]);

  return hideItem ? null : (
    <>
      <Col md={4} sm={6} xs={6} className=' mb-2'>
        <Row className='grid-item justify-content-center mb-2 mt-0'>
          <Col xs={"auto"} className='p-0 h-100'>
            <img
              src={showImg ? image : GradientPlaceholder}
              onLoad={() => setShowImg(true)}
              alt='Product'
              className={!isLiked ? "quiz-image" : "quiz-image-selected"}
              onError={() => {
                console.log("error");
                setHideItem(true);
              }}
              onClick={
                (e) => (temp ? console.log("temp") : toggleLike())
              }
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

function mapStateToProps(state) {
  const { likeDislike } = state;

  const likes = get(likeDislike, "sessionLikes", []);

  return { likeDislike, likes };
}

const connectedQuizCard = connect(mapStateToProps)(QuizCard);
export { connectedQuizCard as QuizCard };
