import { uiConstants } from "../_constants";

export const uiActions = {
  scrollStart,
  scrollStop,
  showCallToAction
};

function scrollStart() {
  return { type: uiConstants.SCROLL_START };
}
function scrollStop() {
  return { type: uiConstants.SCROLL_STOP };
}

function showCallToAction(action) {
  return { type: uiConstants.SHOW_CTA, action };
}
