import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrangeButton from "../../components/Buttons/OrangeButton";
import ErrorBox from "./error-box";
import { userActions } from "../../_actions";
import { usernameAvailable, usernameValid } from "../../_constants";
import MaskedFormControl from "react-maskedinput";
// import { passwordPattern } from "../../_constants/app.constants";

import "./styles.css";

const SignupForm = ({
  dispatch,
  firstName,
  lastName,
  email,
  birthday,
  username,
  // pw,
  errorMessage,
  setPassword,
  password,
  loading,
  likes,
}) => {
  const [validated, setValidated] = useState(false);
  const [slugTaken, setSlugTaken] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);

  var validPassword =
    "^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,16}$";

  var passwordPattern =
    "(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[]{}-_+=~`|:;'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}";

  const name = firstName + " " + lastName;

  const signupObject = {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
    name: name,
    gender: "female",
    dateofbirth: birthday,
    slug: username,
    likedItems: likes,
  };

  const handleSubmit = async (event) => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      if (!usernameValid(username)) {
        setInvalidUsername(true);
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
      const available = await usernameAvailable(username);
      if (available && usernameValid(username)) {
        dispatch(userActions.signup(signupObject));
        setSlugTaken(false);
        setValidated(false);
      } else if (!available) {
        setSlugTaken(true);
        setValidated(false);
      } else if (!usernameValid(username)) {
        setInvalidUsername(true);
        setValidated(false);
      }
    }
  };

  const handleErrors = () => {
    if (errorMessage === "Unauthorized") {
      return "Incorrect Password";
    }
    if (
      errorMessage === "User cannot be confirmed. Current status is CONFIRMED"
    ) {
      return "Account has been confirmed. Please log in.";
    }
    if (errorMessage === "InvalidEmail") {
      return "Email is invalid. Please try again.";
    }
    if (errorMessage === "UserAlreadyExists") {
      return "An account with this email already exists. Please login or use a different email address. ";
    }
    if (errorMessage === "Bad Request") {
      return "Could not create account. Please check your info and try again.";
    }
    if (errorMessage === "InvalidPassword") {
      return "Please update your password.";
    } else {
      return "";
    }
  };

  return (
    <>
      <Row className='justify-content-center'>
        <Col md={12}>
          <h1>Let's get you set up for style!</h1>
        </Col>
        <Col md={12}>
          <p>Create an account to view your recommendations.</p>
        </Col>
        <Col md={12} className='mt-2'>
          <Form
            noValidate
            validated={validated}
            onChange={() => {
              setValidated(false);
              setSlugTaken(false);
              setInvalidUsername(false);
            }}
            onClick={() => dispatch(userActions.clearErrors())}
          >
            <Form.Group align='left'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                as='input'
                value={firstName}
                onChange={(e) =>
                  dispatch(userActions.setFirstName(e.target.value))
                }
              />
              <Form.Control.Feedback type='invalid'>
                Please enter your first name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group align='left'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                as='input'
                value={lastName}
                onChange={(e) =>
                  dispatch(userActions.setLastName(e.target.value))
                }
              />
              <Form.Control.Feedback type='invalid'>
                Please enter your last name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group align='left'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                as='input'
                value={email}
                pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
                onChange={(e) => dispatch(userActions.setEmail(e.target.value))}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid email.
              </Form.Control.Feedback>
            </Form.Group>{" "}
            <Form.Group align='left'>
              <Form.Label>Birthday</Form.Label>

              <Form.Control
                required
                type='date'
                value={birthday}
                pattern='([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))'
                placeholder='YYYY-MM-DD'
                onChange={(e) =>
                  dispatch(userActions.setBirthday(e.target.value))
                }
              />
              {/* <MaskedFormControl
                type="date"
                name="phoneNumber"
                mask="1111-11-11"
                required
              /> */}

              <Form.Control.Feedback type='invalid'>
                Please enter a valid birthday. (YYYY-MM-DD)
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group align='left'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                required
                as='input'
                value={username}
                // pattern="^[a-z0-9_-]{3,16}$"
                onChange={(e) => {
                  setSlugTaken(false);
                  dispatch(userActions.setUsername(e.target.value));
                }}
                isInvalid={slugTaken || invalidUsername}
              />
              <Form.Control.Feedback type='invalid'>
                {!slugTaken
                  ? "Please enter a valid username"
                  : "This username already exists."}
              </Form.Control.Feedback>
              {!usernameValid(username) && (
                <small className='password-hint'>
                  Usernames can only contain lowercase letters, numbers, dashes,
                  underscores, and periods.
                </small>
              )}
            </Form.Group>
            <Form.Group align='left'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                as='input'
                type='password'
                pattern={validPassword}
                onChange={(e) => setPassword(e.target.value)}
                // onChange={e => dispatch(userActions.setReduxPw(e.target.value))}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid password.
              </Form.Control.Feedback>
              <small className='password-hint'>
                Password must be at least 8 characters, including lowercase,
                uppercase, numeric, and special characters.
              </small>
            </Form.Group>
            {errorMessage && <ErrorBox message={handleErrors()} />}
            {errorMessage === "UserNotVerified" && <Redirect to='/verify' />}
            <br />
            <br />
            <OrangeButton
              buttonText={loading ? "Loading..." : "Create Account"}
              onClick={(e) => {
                handleSubmit(e);
              }}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  const { newUser, likeDislike } = state;
  const slugTaken = get(newUser, "slugTaken", "");
  const signupSuccess = get(newUser, "signupSuccess", "");
  const firstName = get(newUser, "data.firstName", "");
  const lastName = get(newUser, "data.lastName", "");
  const email = get(newUser, "data.email", "");
  const birthday = get(newUser, "data.birthday", "");
  const username = get(newUser, "data.username", "");
  const pw = get(newUser, "data.pw", "");
  const errorMessage = get(newUser, "signupError", "");
  const likes = get(likeDislike, "sessionLikes", []);
  const loading = get(newUser, "loading", false);

  return {
    slugTaken,
    signupSuccess,
    firstName,
    lastName,
    email,
    birthday,
    username,
    pw,
    errorMessage,
    loading,
    likes,
  };
}

const connectedSignupForm = connect(mapStateToProps)(SignupForm);
export { connectedSignupForm as SignupForm };
