import React from "react";
import "./styles.css";
import { SiteNav } from "../../components/Navs/SiteNav/index.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Navs/Footer/index.js";
import Container from "react-bootstrap/Container";
import AddTaggBanner from "../../components/AddTaggBanner";
import Brands from "../Landing/brands.js";

const Partners = () => {
  return (
    <>
      <SiteNav />
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={7} sm={12} xs={12} className="shadow-box  p-4">
            <h1 style={{ marginTop: "30px" }}>
              Tagg Me partners with high quality, respected retailers across the
              world.
            </h1>
            <hr className="line-left"></hr>
            <p>
              <Brands />
              Please send an email to{" "}
              <a href="mailto:partner@tagg.me">partner@tagg.me</a> to discuss
              becoming a Tagg Me partner.
            </p>
          </Col>
        </Row>
        <AddTaggBanner />
        <Footer />
      </Container>
    </>
  );
};

export default Partners;
