import React, { useState } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import get from "lodash/get";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrangeButton from "../../components/Buttons/OrangeButton";
import ErrorBox from "../../components/Forms/error-box";
import { appConstants } from "../../_constants";

import "./styles.css";

const ChangePasswordForm = ({ dispatch, pwUpdated, passwordError }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const passwordObj = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };

  var validPassword =
    "^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,16}$";

  const handlePasswordChange = event => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else dispatch(userActions.changePassword(passwordObj));
  };

  return (
    <>
      <Col xs={12} align="left">
        <Form
          noValidate
          validated={validated}
          onChange={() => {
            setValidated(false);
          }}
          onClick={
            passwordError ? () => dispatch(userActions.clearErrors()) : null
          }
        >
          <Form.Group align="left">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              required
              as="input"
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your current password.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group align="left">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              required
              as="input"
              type="password"
              pattern={validPassword}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {newPassword === ""
                ? "Please enter your new password."
                : "Please enter a valid password"}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group align="left">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              required
              as="input"
              type="password"
              pattern={validPassword && newPassword}
              value={checkPassword}
              onChange={e => setCheckPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {checkPassword === ""
                ? "Please confirm your new password."
                : "Passwords do not match"}
            </Form.Control.Feedback>
            <small className="password-hint">
              Password must be at least 8 characters, including lowercase,
              uppercase, numeric, and special characters.
            </small>
          </Form.Group>
          {passwordError && (
            <ErrorBox
              message={
                "Could not update password. Please verify and try again."
              }
            />
          )}
        </Form>
      </Col>
      <br />
      <Col xs={12} align="center">
        {pwUpdated && <p>Password changed!</p>}
        <OrangeButton
          buttonText={"Change Password"}
          onClick={e => handlePasswordChange(e)}
        />
      </Col>
    </>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const passwordError = get(authentication, "error", null);
  const pwUpdated = get(authentication, "updated", false);

  return {
    passwordError,
    pwUpdated
  };
}

const connectedChangePasswordForm = connect(mapStateToProps)(
  ChangePasswordForm
);
export { connectedChangePasswordForm as ChangePasswordForm };
