import React, { useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as BlackSearch } from "../../../assets/svg/search.svg";
import { ReactComponent as Close } from "../../../assets/svg/xclose.svg";
import "./styles.css";

const DesktopCategories = ({
  currentSection,
  setCurrentSection,
  showSearch,
  setShowSearch,
  categories,
  searchTerm,
  setSearchTerm,
  showSearchTerm,
  setShowSearchTerm,
}) => {
  let match = useRouteMatch();

  var str = window.location.toString();
  var lastSlash = str.lastIndexOf("/");
  const section = str.substring(lastSlash + 1);

  useEffect(() => {
    setCurrentSection(section);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setShowSearch(true);
      setCurrentSection(null);
    }
  };

  return (
    <div>
      <>
        <Navbar
          fixed="top"
          className="nav-wrapper mx-auto visibledesktop pl-4 pr-4"
        >
          <Row className="mx-auto m-0 categories-container">
            {categories.map((category, index) => (
              <div className="dropdown" key={index}>
                <span>
                  <Link
                    className={
                      currentSection === category.id
                        ? "dropdown-underline"
                        : "dropdown-title"
                    }
                    to={(() => {
                      switch (category.id) {
                        case 0:
                          return "/home";
                        case 6:
                          return "/home/tagged";
                        default:
                          return `${match.url}/category/${category.id}`;
                      }
                    })()}
                    onClick={() => {
                      setCurrentSection(category.id);
                      setShowSearch(false);
                      setShowSearchTerm(false);
                    }}
                  >
                    {category.categoryName}
                  </Link>
                </span>
              </div>
            ))}

            <div className="dropdown">
              <div className="visibledesktop ">
                <Row className="m-0 align-items-center">
                  {showSearchTerm ? (
                    <>
                      <BlackSearch
                        style={{ marginTop: "1px" }}
                        onClick={() => {
                          setShowSearchTerm(false);
                          setShowSearch(false);
                          setSearchTerm("");
                          setCurrentSection(section);
                        }}
                      />
                      <Form.Control
                        className="search-box"
                        value={searchTerm}
                        autoFocus={true}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                      />
                      <Close
                        style={{ marginBottom: "2px", height: "10px" }}
                        onClick={() => {
                          setShowSearchTerm(false);
                          setShowSearch(false);
                          setSearchTerm("");
                          setCurrentSection(section);
                        }}
                      />
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        setShowSearchTerm(true);
                      }}
                      style={{ marginTop: "10px", fontSize: "12px" }}
                    >
                      <BlackSearch style={{ marginTop: "2px" }} /> SEARCH
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </Row>
        </Navbar>
      </>
    </div>
  );
};

function mapStateToProps(state) {
  const { siteConfig } = state;
  const categories = siteConfig.categories;

  return {
    categories,
  };
}

const connectedDesktopCategories = connect(mapStateToProps)(DesktopCategories);
export { connectedDesktopCategories as DesktopCategories };
