import React, { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { Quickview } from "./index";

import Modal from "react-bootstrap/Modal";

const QuickviewModal = (setShowCta) => {
  let history = useHistory();
  let back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  function useLockBodyScroll() {
    useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
  }

  useLockBodyScroll();

  return (
    <div className='qv-body'>
      <div className='qv-modal'>
        <Quickview backgroundBack={back} setShowCta={setShowCta} />
      </div>
    </div>
  );
};

export default QuickviewModal;
